import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

import { LooperBackground, TotalPaidLogo } from '../../../icons';
import { numberToAmount } from '../../../utils';
import { MakePayment } from './MakePayment';

export const HomeLeftSection = (props) => {
  const { totalAmount, paidAmount } = props;
  return (
    <div className="left-section">
      <div className="total-paid-amount">
        <TotalPaidLogo percentage={(paidAmount / totalAmount) * 100} />
        <div className="paid-details">
          <div className="title">
            {i18next.t('DASHBOARD.BUILDER_PAYMENTS.TOTAL_PAID_AMOUNT')}
          </div>
          <div className="amount">{numberToAmount({ number: paidAmount, limit: true })}</div>
        </div>
        <LooperBackground className="looper-background" />
      </div>
      <MakePayment {...props} />
    </div>
  );
};

HomeLeftSection.propTypes = {
  paidAmount: PropTypes.number,
  totalAmount: PropTypes.number,
};
HomeLeftSection.defaultProps = {
  paidAmount: 0,
  totalAmount: 0,
};
