import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={24} height={16} viewBox="0 0 24 16" fill="none" {...props}>
      <path fill="url(#prefix__pattern0)" d="M0 0h24v16H0z" />
      <defs>
        <pattern
          id="prefix__pattern0"
          patternContentUnits="objectBoundingBox"
          width={1}
          height={1}
        >
          <use xlinkHref="#prefix__image0" transform="scale(.04167 .0625)" />
        </pattern>
        <image
          id="prefix__image0"
          width={24}
          height={16}
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAQCAYAAAAMJL+VAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAGKADAAQAAAABAAAAEAAAAADSRT5sAAABf0lEQVQ4EZ2USytFURiGHRImUlJHyikDGYtEJi4DSRkfyi/wD5gZSRkaG4gfoERMKJEJJZHUuRDJtZMBEZ73ZJW99l7rbN562nt977e+tddlr0RZWO2ExqAL6iEPZ7AIR1AJ49AHbVADOdiAJXiGSNUSXYEvD+t4F7AMI9AKKRiABbiFCQipjsgx+IobL0teI0Spk+AJTNnmKgFTIM5zj/yEXeSnrY+9hGHja3pxito52geXhjBOoVwJ2hi7c5z2pjp7tI3Xo2lmIQV/1SsddIJcmsZ41xSSrowS8Wp8rbdL1xhJDVBwZZSIf+K/eHJ07AsaQMfqPzqn04enYwdesfYkL3E21c6Z8RRvwrsBzaK4UTq3dgFf+4F8XSMurWEEPqCXwBv4ihpPyzIKUdI9NQu7UGUnDBJ4BFMo6qniGdBfav/J3cQOQZee83Q1YM6D1u/3APe050BHuh+2QBfbAezAFexDGgIDBxqYRoo3g9ZZ+3MHtioItIB+NuU8QUjfxFSecmbUkLAAAAAASUVORK5CYII="
        />
      </defs>
    </svg>
  );
}

export default SvgComponent;
