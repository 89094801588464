import i18next from 'i18next';

export const BUSINESS_ENTITY_DATA = () => [
  {
    title: i18next.t('BUSINESS_PROFILE.BUSINESS_ENTITY.OPTION1'),
    value: 'corporation',
  },
  {
    title: i18next.t('BUSINESS_PROFILE.BUSINESS_ENTITY.OPTION2'),
    value: 'partnership',
  },
  {
    title: i18next.t('BUSINESS_PROFILE.BUSINESS_ENTITY.OPTION3'),
    value: 'trust',
  },
  {
    title: i18next.t('BUSINESS_PROFILE.BUSINESS_ENTITY.OPTION4'),
    value: 'llc',
  },
];

export const GOOD_STANDING_DATA = () => [
  {
    title: i18next.t('BUSINESS_PROFILE.GOOD_STANDING.OPTION1'),
    value: 'yes',
  },
  {
    title: i18next.t('BUSINESS_PROFILE.GOOD_STANDING.OPTION2'),
    value: 'no',
  },
];

export const GOVERNING_DOCS_DATA = () => [
  {
    title: i18next.t('BUSINESS_PROFILE.GOVERNING_DOCS.SECTION1.TITLE'),
    subTitle: i18next.t('BUSINESS_PROFILE.GOVERNING_DOCS.SECTION1.SUBTITLE'),
    key: 'corporationDoc',
  },
  {
    title: i18next.t('BUSINESS_PROFILE.GOVERNING_DOCS.SECTION2.TITLE'),
    subTitle: i18next.t('BUSINESS_PROFILE.GOVERNING_DOCS.SECTION2.SUBTITLE'),
    key: 'partnershipAgreementDoc',
  },
  {
    title: i18next.t('BUSINESS_PROFILE.GOVERNING_DOCS.SECTION3.TITLE'),
    subTitle: i18next.t('BUSINESS_PROFILE.GOVERNING_DOCS.SECTION3.SUBTITLE'),
    key: 'operatingAgreementDoc',
  },
  {
    title: i18next.t('BUSINESS_PROFILE.GOVERNING_DOCS.SECTION4.TITLE'),
    subTitle: i18next.t('BUSINESS_PROFILE.GOVERNING_DOCS.SECTION4.SUBTITLE'),
    key: 'trustAgreementDoc',
  },
  {
    title: i18next.t('BUSINESS_PROFILE.GOVERNING_DOCS.SECTION5.TITLE'),
    subTitle: '',
    key: 'otherDoc',
  },
];

export const TAX_CLASSIFICATION_DATA = () => [
  {
    title: i18next.t('BUSINESS_PROFILE.TAX_CLASSIFICATION.OPTION1'),
    value: 'ccorp',
  },
  {
    title: i18next.t('BUSINESS_PROFILE.TAX_CLASSIFICATION.OPTION2'),
    value: 'scorp',
  },
  {
    title: i18next.t('BUSINESS_PROFILE.TAX_CLASSIFICATION.OPTION3'),
    value: 'partnership',
  },
  {
    title: i18next.t('BUSINESS_PROFILE.TAX_CLASSIFICATION.OPTION4'),
    value: 'trust',
  },
  {
    title: i18next.t('BUSINESS_PROFILE.TAX_CLASSIFICATION.OPTION5'),
    value: 'disregarded',
  },
];
