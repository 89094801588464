import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <path opacity={0.01} stroke="#979797" d="M.5.5h31v31H.5z" />
      <path
        clipRule="evenodd"
        d="M18 6h-8a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V12l-6-6z"
        stroke="#000432"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 6v6h6M20 17h-8M20 21h-8M14 13h-2"
        stroke="#000432"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
