import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

import { OWNER_TYPE_DATA, OWNERS_DATA } from '../../data';
import { HelpIcon } from '../../icons';
import { onlyNumbers, onlyString } from '../../utils/textInputValid';
import { FormInput, RadioInput } from '../inputs';
import { EquityBlock, OwnerEquityID } from './common';
import { ControlButton } from './ControlButton';

export const OwnershipForm = ({
  active, next, prev, data, updateState,
}) => {
  const {
    owner, ownerType, ownerName, ownerAddress,
    shareOwnership,
    llcMembership,
    partnershipAllocation,
    otherInterest,
    ownerId,
    ownerIdType,
  } = data;
  const updateFormState = (payload) => updateState('ownership', payload);

  return (
    <form
      method="post"
      onSubmit={(e) => { e.preventDefault(); next(); }}
      className={`form-content ownership-director-management-container ${active ? 'active' : ''}`}
    >
      <div className="form-title">{i18next.t('OWNERSHIP.TITLE')}</div>
      <div className="form-section ownership-more-10">
        <div className="section-content">
          <div className="form-text-title">{i18next.t('OWNERSHIP.BLOCK1.TEXT')}</div>
          <div className="ownership-more-radio">
            {OWNERS_DATA().map(({ title, value }) => (
              <RadioInput
                key={title}
                name="owner"
                title={title}
                value={value}
                selected={owner}
                onChange={(value) => updateFormState({ owner: value })}
                required
              />
            ))}
          </div>
        </div>
      </div>
      <div className="form-text-title ownership-more-help">
        {i18next.t('OWNERSHIP.OPEN_TEXT1')}
      </div>
      <div className="form-section owner-type">
        <div className="section-title">{ i18next.t('OWNERSHIP.BLOCK2.TITLE') }</div>
        <div className="section-content">
          <div className="owner-type-radio">
            {OWNER_TYPE_DATA().map(({ title, value }) => (
              <RadioInput
                key={title}
                name="owner-type"
                title={title}
                value={value}
                selected={ownerType}
                onChange={(value) => updateFormState({ ownerType: value })}
                required
              />
            ))}
          </div>
          <div className="form-light-description owner-type-text">
            {i18next.t('OWNERSHIP.BLOCK2.HINT')}
          </div>
        </div>
      </div>
      <div className="form-section">
        <div className="section-block column-2">
          <FormInput
            label={i18next.t('OWNERSHIP.NAME.LABEL')}
            placeholder={i18next.t('OWNERSHIP.NAME.LABEL')}
            description={i18next.t('OWNERSHIP.NAME.HINT')}
            value={ownerName}
            onChange={(e) => updateFormState({ ownerName: onlyString(e.target.value, 50) })}
            required
          />
        </div>
      </div>
      <div className="form-section">
        <div className="section-block">
          <FormInput
            label={i18next.t('OWNERSHIP.ADDRESS.LABEL')}
            placeholder={i18next.t('OWNERSHIP.ADDRESS.LABEL')}
            description={i18next.t('OWNERSHIP.ADDRESS.HINT')}
            value={ownerAddress}
            onChange={(e) => updateFormState({ ownerAddress: onlyString(e.target.value, 100) })}
            required
          />
        </div>
      </div>
      <EquityBlock
        title={i18next.t('OWNERSHIP.BLOCK3.TITLE')}
        updateFormState={updateFormState}
        shareOwnership={shareOwnership}
        partnershipAllocation={partnershipAllocation}
        llcMembership={llcMembership}
        otherInterest={otherInterest}
        shareOwnershipKey="shareOwnership"
        partnershipAllocationKey="partnershipAllocation"
        llcMembershipKey="llcMembership"
        otherInterestKey="otherInterest"
      />
      <div className="form-section owner-identification">
        <div className="section-title">
          {i18next.t('OWNERSHIP.OWNER_ID.TITLE')}
          <HelpIcon
            className="help-icon"
            data-for="help-tooltip"
            data-tip={i18next.t('OWNERSHIP.OWNER_ID.HINT')}
            data-iscapture="true"
            data-event="click focus"
            data-event-off="blur"
          />
        </div>
        <div className="section-block column-2">
          <FormInput
            label={i18next.t('OWNERSHIP.OWNER_ID.TITLE')}
            placeholder={i18next.t('OWNERSHIP.OWNER_ID.TITLE')}
            value={ownerId}
            onChange={(e) => updateFormState({ ownerId: onlyNumbers(e.target.value, 10) })}
            required
          />
        </div>
      </div>

      <OwnerEquityID
        name="owner-id-type"
        updateFormState={updateFormState}
        ownerIdType={ownerIdType}
        ownerIdTypeKey="ownerIdType"
        ownerIdDocKey="ownerIdDoc"
      />

      <ControlButton
        nextTitle={i18next.t('BUTTONS.NEXT')}
        prevTitle={i18next.t('BUTTONS.PREVIOUS')}
        onPrev={prev}
        buttonPropsNext={{ type: 'submit' }}
      />
    </form>
  );
};

OwnershipForm.propTypes = {
  next: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,
  updateState: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  active: PropTypes.bool.isRequired,
};
OwnershipForm.defaultProps = {};
