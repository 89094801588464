import { useStoreState } from 'easy-peasy';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';

import { Layout, LoanRejected } from '../components';

export const LoanRejectedPage = ({ history: { push } }) => {
  const applicationData = useStoreState(({ userApplication }) => userApplication);
  useEffect(() => {
    if (!isEmpty(applicationData)) {
      if (applicationData.status === 'waiting') push('/review');
      else if (applicationData.status === 'approved') push('/dashboard');
    } else {
      push('/loan-form');
    }
  }, [applicationData]);
  return (
    <Layout>
      <LoanRejected onClick={() => push('/dashboard/home')} application={applicationData} />
    </Layout>
  );
};

LoanRejectedPage.propTypes = {};
LoanRejectedPage.defaultProps = {};
