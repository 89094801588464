import axios from 'axios';
import i18next from 'i18next';
import React from 'react';

import { extensionMap } from '../../../constants';
import { PURPOSE_DATA } from '../../../data';
import { DownloadIcon } from '../../../icons';
import { numberToAmount, numberToMonth, valueToTitle } from '../../../utils';

export const DATA_MAP = (businessProfile) => {
  const {
    legalName, annualRevenuePriorYear, taxID, bankStatement, email,
  } = businessProfile;
  const downloadFile = (path) => {
    // console.log('downloadFile path: ', path);
    axios.get(path, {
      responseType: 'blob',
    })
      .then((response) => {
        const fileType = response.data.type;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `file.${extensionMap[fileType]}`); // or any other extension
        document.body.appendChild(link);
        link.click();
      });
  };
  return [
    {
      title: i18next.t('APPLICATION_DETAIL_PAGE.KEYS.TITLE1'),
      value: legalName,
    },
    {
      title: i18next.t('APPLICATION_DETAIL_PAGE.KEYS.TITLE2'),
      value: numberToAmount({ number: annualRevenuePriorYear }),
    },
    {
      title: i18next.t('APPLICATION_DETAIL_PAGE.KEYS.TITLE3'),
      value: 'Fair',
    },
    {
      title: i18next.t('APPLICATION_DETAIL_PAGE.KEYS.TITLE4'),
      value: taxID,
    },
    {
      title: i18next.t('APPLICATION_DETAIL_PAGE.KEYS.TITLE5'),
      value: (
        <div
          className="detail-desc"
          target="_blank"
          rel="noreferrer"
          onClick={() => downloadFile(bankStatement)}
        >
          Download bank statement
        </div>),
      Icon: (
        <DownloadIcon
          className="download-icon"
          onClick={() => downloadFile(bankStatement)}
        />
      ),
    },
    {
      title: i18next.t('APPLICATION_DETAIL_PAGE.KEYS.TITLE6'),
      value: email,
    },
  ];
};

// for `Business Details` tab
export const DATA_MAP2 = (businessProfile) => {
  const {
    legalName, naicsCode, businessPrimaryAdd, annualRevenuePriorYear,
    taxID, organizedYear, annualProfitPriorYear, industry, stateRegNo,
    primaryBank, phone, email,
  } = businessProfile;
  return [
    {
      title: i18next.t('APPLICATION_DETAIL_PAGE.KEYS.TITLE1'),
      value: legalName,
    },

    {
      title: i18next.t('APPLICATION_DETAIL_PAGE.KEYS.TITLE7'),
      value: naicsCode,
    },

    {
      title: i18next.t('APPLICATION_DETAIL_PAGE.KEYS.TITLE2'),
      value: numberToAmount({ number: annualRevenuePriorYear }),
    },
    {
      title: i18next.t('APPLICATION_DETAIL_PAGE.KEYS.TITLE9'),
      value: businessPrimaryAdd,
    },
    {
      title: i18next.t('APPLICATION_DETAIL_PAGE.KEYS.TITLE4'),
      value: taxID,
    },
    {
      title: i18next.t('APPLICATION_DETAIL_PAGE.KEYS.TITLE11'),
      value: organizedYear,
    },
    {
      title: i18next.t('APPLICATION_DETAIL_PAGE.KEYS.TITLE12'),
      value: numberToAmount({ number: annualProfitPriorYear }),
    },
    {
      title: i18next.t('APPLICATION_DETAIL_PAGE.KEYS.TITLE13'),
      value: phone,
    },
    {
      title: i18next.t('APPLICATION_DETAIL_PAGE.KEYS.TITLE14'),
      value: industry,
    },
    {
      title: i18next.t('APPLICATION_DETAIL_PAGE.KEYS.TITLE15'),
      value: stateRegNo,
    },
    {
      title: i18next.t('APPLICATION_DETAIL_PAGE.KEYS.TITLE16'),
      value: primaryBank,
    },
    {
      title: i18next.t('APPLICATION_DETAIL_PAGE.KEYS.TITLE10'),
      value: email,
    },
  ];
};

export const DATA_CARD_MAP = (loanRequest) => {
  const { meritTerm, purpose = [] } = loanRequest;
  return [
    // {
    //   title: i18next.t('APPLICATION_DETAIL_PAGE.KEYS.TITLE18'),
    //   value: numberToAmount({number:meritAmount}),
    // },
    {
      title: i18next.t('APPLICATION_DETAIL_PAGE.KEYS.TITLE19'),
      value: numberToMonth(meritTerm),
    },
    {
      title: i18next.t('APPLICATION_DETAIL_PAGE.KEYS.TITLE20'),
      value: valueToTitle(purpose, PURPOSE_DATA()).join(', '),
    }];
};
