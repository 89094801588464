import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {
  FINAL_RADIO_DATA, TERMS_DATA,
} from '../../data';
import { onlyString } from '../../utils';
import { CheckBox, FormInput, RadioInput } from '../inputs';
import { DocumentUpload } from './common/DocumentUpload';
import { ControlButton } from './ControlButton';

export const FinalSubmissionForm = ({
  active, prev, data, updateState, onSubmit,
}) => {
  const {
    certificateOne,
    certificateOneDetail,
    certificateTwo,
    certificateTwoDetail,
  } = data;
  const [privacy, setPrivacy] = useState([]);
  const [agreement, setAgreement] = useState([]);
  const agreementStateMap = [
    { selected: privacy, onChange: setPrivacy },
    { selected: agreement, onChange: setAgreement },
  ];
  const updateFormState = (payload) => updateState('certifications', payload);
  return (
    <form
      method="post"
      onSubmit={(e) => { e.preventDefault(); onSubmit(); }}
      className={`form-content final-submission ${active ? 'active' : ''}`}
    >
      <div className="form-title">{ i18next.t('CERTIFICATIONS.TITLE') }</div>
      <div className="form-section certification-radios-section">
        <div className="section-content">
          <div className="certification-radios-content">
            <div className="form-text-title certification-radios-text">
              {i18next.t('CERTIFICATIONS.SECTION_INPUT.TITLE1')}
            </div>
            <div className="certification-radio">
              {FINAL_RADIO_DATA().map(({ title, value }) => (
                <RadioInput
                  key={title}
                  name="certificate-one"
                  title={title}
                  value={value}
                  selected={certificateOne}
                  onChange={(value) => {
                    let newCertificateOneDetail = certificateOneDetail;
                    if (value === 'no') {
                      newCertificateOneDetail = '';
                    }
                    updateFormState({
                      certificateOne: value,
                      certificateOneDetail: newCertificateOneDetail,
                    });
                  }}
                  required
                />
              ))}
            </div>
          </div>
          <div className="certification-radios-input">
            <FormInput
              label={i18next.t('CERTIFICATIONS.SECTION_INPUT.INPUT_PLACEHOLDER')}
              placeholder={i18next.t('CERTIFICATIONS.SECTION_INPUT.INPUT_PLACEHOLDER')}
              value={certificateOneDetail}
              onChange={({ target: { value } }) => updateFormState({
                certificateOneDetail: onlyString(value), certificateOne: 'yes',
              })}
              required={certificateOne === 'yes'}
            />
          </div>
        </div>
      </div>
      <div className="form-section certification-radios-section">
        <div className="section-content">
          <div className="certification-radios-content">
            <div className="form-text-title certification-radios-text">
              {i18next.t('CERTIFICATIONS.SECTION_INPUT.TITLE1')}
            </div>
            <div className="certification-radio">
              {FINAL_RADIO_DATA().map(({ title, value }) => (
                <RadioInput
                  key={title}
                  name="certificate-two"
                  title={title}
                  value={value}
                  selected={certificateTwo}
                  onChange={(value) => {
                    let newCertificateTwoDetail = certificateTwoDetail;
                    if (value === 'no') {
                      newCertificateTwoDetail = '';
                    }
                    updateFormState({
                      certificateTwo: value,
                      certificateTwoDetail: newCertificateTwoDetail,
                    });
                  }}
                  required
                />
              ))}
            </div>
          </div>
          <div className="certification-radios-input">
            <FormInput
              label={i18next.t('CERTIFICATIONS.SECTION_INPUT.INPUT_PLACEHOLDER')}
              placeholder={i18next.t('CERTIFICATIONS.SECTION_INPUT.INPUT_PLACEHOLDER')}
              value={certificateTwoDetail}
              onChange={({ target: { value } }) => updateFormState({
                certificateTwoDetail: onlyString(value), certificateTwo: 'yes',
              })}
              required={certificateTwo === 'yes'}
            />
          </div>
        </div>
      </div>
      <DocumentUpload
        className="nda-document-upload"
        title={i18next.t('CERTIFICATIONS.SECTION2.TITLE')}
        subTitle={i18next.t('CERTIFICATIONS.SECTION2.SUB_TITLE')}
        description={i18next.t('CERTIFICATIONS.SECTION2.DESCRIPTION')}
        onUpload={(url) => updateFormState({ ndaDoc: url })}
        required
      />
      <div className="form-title merit-documentation">
        {i18next.t('CERTIFICATIONS.SECTION1.TITLE')}
      </div>
      <div className="documentation-checkboxes">
        {TERMS_DATA().map((node, i) => ({ ...node, ...agreementStateMap[i] }))
          .map(({
            title, value, url, selected, onChange,
          }) => (
            <div key={title} className="docu-checkbox-node">
              <CheckBox
                name={value}
                title={title}
                value={value}
                selected={selected}
                onChange={onChange}
                required
              />
              <a
                href={url}
                target="_blank"
                rel="noreferrer"
                className="form-description-text view-text"
              >
                {i18next.t('CERTIFICATIONS.SECTION1.VIEW')}
              </a>
            </div>
          ))}
      </div>
      <div className="form-light-description agreement-check">
        {i18next.t('CERTIFICATIONS.SECTION1.AGREE_SECTION')}
      </div>
      <ControlButton
        nextTitle={i18next.t('BUTTONS.SUBMIT')}
        prevTitle={i18next.t('BUTTONS.PREVIOUS')}
        onPrev={prev}
        buttonPropsNext={{ type: 'submit' }}
      />
    </form>
  );
};

FinalSubmissionForm.propTypes = {
  prev: PropTypes.func.isRequired,
  updateState: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
};
FinalSubmissionForm.defaultProps = {};
