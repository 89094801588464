import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={216}
      height={265}
      viewBox="0 0 216 265"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="#EAEAF6" d="M0 0h216v265H0z" />
      <path
        fill="#D4D5E9"
        d="M21 24h174v19H21zM21 70h103v13H21zM21 146h103v13H21zM21 96h143v13H21zM21 172h143v13H21zM21 220h143v13H21zM21 120h155v13H21zM21 196h155v13H21z"
      />
    </svg>
  );
}

export default SvgComponent;
