import i18next from 'i18next';

import { onlyNumbers, onlyString } from '../../../../utils/textInputValid';

export const BUSSINESS_NAME_DATA = ({
  updateFormState, legalName, businessAlias, taxID, industry, naicsCode, organizedYear, stateRegNo,
}) => [
  {
    title: 'row-1',
    className: 'column-3',
    rowData: [
      {
        label: i18next.t('BUSINESS_PROFILE.NAME'),
        value: legalName,
        onChange: (v) => updateFormState({ legalName: onlyString(v, 50) }),
        required: true,
      },
      {
        label: i18next.t('BUSINESS_PROFILE.ALIAS'),
        value: businessAlias,
        onChange: (v) => updateFormState({ businessAlias: onlyString(v, 50) }),
        required: false,
      },
      {
        label: i18next.t('BUSINESS_PROFILE.TAX_ID.LABEL'),
        value: taxID,
        onChange: (v) => updateFormState({ taxID: onlyNumbers(v, 9) }),
        required: true,
      },
    ],
  },
  {
    title: 'row-2',
    className: '',
    rowData: [
      {
        label: i18next.t('BUSINESS_PROFILE.INDUSTRY'),
        value: industry,
        onChange: (v) => updateFormState({ industry: onlyString(v, 100) }),
        required: true,
      },
    ],
  },
  {
    title: 'row-3',
    className: 'column-3',
    rowData: [
      {
        label: i18next.t('BUSINESS_PROFILE.NAICS'),
        value: naicsCode,
        onChange: (v) => updateFormState({ naicsCode: onlyNumbers(v, 6) }),
        required: true,
        inputProps: {
          minLength: '2',
        },
      },
      {
        label: i18next.t('BUSINESS_PROFILE.YEAR.LABEL'),
        value: organizedYear,
        onChange: (v) => updateFormState({ organizedYear: onlyNumbers(v, 4) }),
        description: i18next.t('BUSINESS_PROFILE.YEAR.HINT'),
        required: true,
        inputProps: {
          pattern: String.raw`^(18[5-9]\d|19[0-9]\d|20[0-4]\d|2050)$`,
          title: i18next.t('BUSINESS_PROFILE.YEAR.TITLE'),
          minLength: '4',
        },
      },
      {
        label: i18next.t('BUSINESS_PROFILE.REG_NO.LABEL'),
        value: stateRegNo,
        onChange: (v) => updateFormState({ stateRegNo: onlyNumbers(v, 12) }),
        description: i18next.t('BUSINESS_PROFILE.REG_NO.HINT'),
        required: true,
      },
    ],
  },
];
