import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { numberToAmount } from '../../../utils';

export const HistoryListItem = ({
  cardUsed, cardName, amount, date,
}) => (
  <div className="payment-list-node">
    <div className="info-block">
      <div className="upper-text">{`xxxx ${cardUsed}`}</div>
      <div className="lower-text" title={cardName}>{cardName}</div>
    </div>
    <div className="info-block">
      <div className="upper-text right">{numberToAmount({ number: amount, limit: true })}</div>
      <div className="lower-text right">{moment(date).format('DD MMM YYYY')}</div>
    </div>
  </div>
);

HistoryListItem.propTypes = {
  amount: PropTypes.number.isRequired,
  cardUsed: PropTypes.string.isRequired,
  cardName: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};
HistoryListItem.defaultProps = {};
