import i18next from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import { ReloadIcon } from '../../../icons';
import { HistoryListItem } from '../home/HistoryListItem';

export const PaymentHistory = ({ payments, getPaymentHistory }) => {
  const listBodyRef = useRef();
  const onReloadClick = () => {
    getPaymentHistory();
    listBodyRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const sortedPaymentHistory = payments.sort((a, b) => Number(
    moment(b.paidAt).unix(),
  ) - Number(moment(a.paidAt).unix()));
  return (
    <div className="payment-history">
      <ReloadIcon className="reload-icon" onClick={onReloadClick} />
      <div className="payment-history-title block-title">
        {i18next.t('DASHBOARD.BUILDER_PAYMENTS.HISTORY_TITLE')}
      </div>
      <div className="payment-history-list" ref={listBodyRef}>
        {sortedPaymentHistory.map(({
          cardUsed, cardName, amount, paidAt,
        }) => (
          <HistoryListItem
            key={paidAt}
            cardUsed={cardUsed}
            cardName={cardName}
            amount={amount}
            date={paidAt}
          />
        ))}
      </div>
    </div>
  );
};

PaymentHistory.propTypes = {
  payments: PropTypes.instanceOf(Array),
  getPaymentHistory: PropTypes.func,
};
PaymentHistory.defaultProps = {
  payments: [],
  getPaymentHistory: () => {},
};
