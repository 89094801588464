import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={32}
      height={33}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path opacity={0.01} stroke="#979797" d="M.5.737h31v31H.5z" />
      <path
        d="M22 10.238l-12 12M10 10.238l12 12"
        stroke="#000432"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
