import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import { CommonTable } from '../../../common';
import { AdminLayout } from '../../AdminLayout';
import { ApplicationListItem } from './ApplicationListItem';

const APPLICATION_TABLE_NAV = () => [
  {
    title: i18next.t('APPLICATION_PAGE_NAV.NAV1'),
    value: 'all_requests',
  }, {
    title: i18next.t('APPLICATION_PAGE_NAV.NAV2'),
    value: 'approved',
  }, {
    title: i18next.t('APPLICATION_PAGE_NAV.NAV3'),
    value: 'waiting',
  }, {
    title: i18next.t('APPLICATION_PAGE_NAV.NAV4'),
    value: 'declined',
  },
];

export const ApplicationTable = ({ applications }) => {
  const [subNav, setSubNav] = useState(APPLICATION_TABLE_NAV()[0].value);
  const tableBodyRef = useRef();
  const filteredApplicationData = (subNav === APPLICATION_TABLE_NAV()[0].value)
    ? applications
    : applications.filter(({ status }) => subNav.toLowerCase() === status);
  return (
    <AdminLayout
      title={i18next.t('APPLICATION_PAGE.HEADING')}
      subNavData={APPLICATION_TABLE_NAV()}
      subNavOnClick={(nav) => {
        setSubNav(nav);
        tableBodyRef.current.scrollTo({ top: 0, behavior: 'smooth' });
      }}
      subNavActive={subNav}
    >
      <CommonTable
        headerList={[
          i18next.t('APPLICATION_PAGE.APPLICATION_TABLE.COL1'),
          i18next.t('APPLICATION_PAGE.APPLICATION_TABLE.COL2'),
          i18next.t('APPLICATION_PAGE.APPLICATION_TABLE.COL3'),
          i18next.t('APPLICATION_PAGE.APPLICATION_TABLE.COL4'),
          i18next.t('APPLICATION_PAGE.APPLICATION_TABLE.COL5'),
        ]}
        bodyRef={tableBodyRef}
      >
        {filteredApplicationData.map(({
          _id,
          businessProfile: { legalName },
          loanRequest: { meritTerm, meritAmount },
          status,
        }) => (
          <ApplicationListItem
            key={_id}
            _id={_id}
            legalName={legalName}
            meritAmount={meritAmount}
            meritTerm={meritTerm}
            status={status}
          />
        ))}
      </CommonTable>
    </AdminLayout>
  );
};

ApplicationTable.propTypes = {
  applications: PropTypes.instanceOf(Array).isRequired,
};
ApplicationTable.defaultProps = {};
