import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <path opacity={0.01} stroke="#979797" d="M.5.5h31v31H.5z" />
      <path
        d="M22 12a6 6 0 00-12 0c0 7-3 9-3 9h18s-3-2-3-9M17.73 25a2 2 0 01-3.46 0"
        stroke="#000432"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
