import i18next from 'i18next';

export const PURPOSE_DATA = () => [{
  title: i18next.t('LOAN_REQUEST.PURPOSE.OPTION1'),
  value: 'reserves_savings',
},
{
  title: i18next.t('LOAN_REQUEST.PURPOSE.OPTION2'),
  value: 'risk_mitigation',
},
{
  title: i18next.t('LOAN_REQUEST.PURPOSE.OPTION3'),
  value: 'credit_building',
}];

export const AMOUNT_DATA = () => [
  {
    title: i18next.t('LOAN_REQUEST.AMOUNT.OPTION1'),
    value: 10000,
  },
  {
    title: i18next.t('LOAN_REQUEST.AMOUNT.OPTION2'),
    value: 50000,
  },
  {
    title: i18next.t('LOAN_REQUEST.AMOUNT.OPTION3'),
    value: 100000,
  },
  {
    title: i18next.t('LOAN_REQUEST.AMOUNT.OPTION4'),
    value: 250000,
  },
];

export const TERM_DATA = () => [
  {
    title: i18next.t('LOAN_REQUEST.TERM.OPTION1'),
    value: 6,
  },
  {
    title: i18next.t('LOAN_REQUEST.TERM.OPTION2'),
    value: 12,
  },
  {
    title: i18next.t('LOAN_REQUEST.TERM.OPTION3'),
    value: 18,
  },
  {
    title: i18next.t('LOAN_REQUEST.TERM.OPTION4'),
    value: 24,
  },
  {
    title: i18next.t('LOAN_REQUEST.TERM.OPTION5'),
    value: 36,
  }];

export const RESERVE_DATA = (slice = 0, sliceEnd = 6) => [
  {
    title: i18next.t('LOAN_REQUEST.RESERVE.OPTION1'),
    value: 'merit_trust',
  },
  {
    title: i18next.t('LOAN_REQUEST.RESERVE.OPTION2'),
    value: 'third_party_bank',
  },
  {
    title: i18next.t('LOAN_REQUEST.RESERVE.OPTION3'),
    value: 'third_party_sipc',
  },
  {
    title: i18next.t('LOAN_REQUEST.RESERVE.OPTION4'),
    value: 'liquid',
  },
  {
    title: i18next.t('LOAN_REQUEST.RESERVE.OPTION5'),
    value: 'market_funds',
  },
  {
    title: i18next.t('LOAN_REQUEST.RESERVE.OPTION6'),
    value: 'merit_multiplier',
  },
].slice(slice, sliceEnd);
