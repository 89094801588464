import { remove } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { CheckBoxIcon, CheckBoxTick } from '../../icons';

export const CheckBox = ({
  selected, title, value, className, onChange, required,
}) => {
  const toggleCheckBox = (value) => {
    const newSelected = !selected.includes(value)
      ? [...selected, value]
      : remove([...selected], (n) => (n !== value));
    onChange(newSelected);
  };
  return (
    <div
      className={`checkbox-container ${className}`}
      onClick={(e) => {
        e.stopPropagation();
        toggleCheckBox(value);
      }}
    >
      {selected.includes(value) ? <CheckBoxTick /> : <CheckBoxIcon />}
      <div className="title">{title}</div>
      <input
        className="validation-checkbox"
        type="checkbox"
        onChange={() => {}}
        required={required}
        checked={selected.length}
      />
    </div>
  );
};

CheckBox.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.instanceOf(Array),
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  required: PropTypes.bool,
};
CheckBox.defaultProps = {
  className: '',
  selected: [],
  required: false,
};
