export const extensionMap = {
  'image/png': 'png',
  'application/pdf': 'pdf',
  'image/jpeg': 'jpg',
  'image/bmp': '.bmp',
  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'application/vnd.ms-powerpoint': 'ppt',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
  'image/svg+xml': 'svg',
};
