import React from 'react';
import ReactTooltip from 'react-tooltip';

import { Form, Layout } from '../components';

export const FormPage = ({ history: { push } }) => (
  <Layout>
    <Form push={push} />
    <ReactTooltip id="help-tooltip" place="right" effect="solid" clickable />
    <ReactTooltip id="upload-tooltip" place="bottom" effect="solid" clickable />
  </Layout>
);
FormPage.propTypes = {};
FormPage.defaultProps = {};
