import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

import { UploadInput } from '../../inputs';

export const ProfileSetting = ({
  setEmail, setName, setImage, updateUser, name, email, image, blob,
}) => (
  <form className="profile-setting" method="post" onSubmit={(e) => { e.preventDefault(); updateUser(); }}>
    <div className="profile-photo-section">
      <div className="profile-photo" style={image ? { backgroundImage: `url('${blob}')` } : {}} />
      <UploadInput
        className="upload-photo-button"
        title={i18next.t('SETTINGS.UPLOAD_PHOTO_BTN')}
        fileTypes={['image/png', 'image/jpeg']}
        onUpload={(url) => setImage(url)}
        hideFileTitle
      />
      <div className="remove-photo-button" onClick={() => setImage('')}>{i18next.t('SETTINGS.REMOVE_PHOTO_BTN')}</div>
    </div>
    {[{ title: i18next.t('SETTINGS.NAME'), onChange: setName, value: name },
      { title: i18next.t('SETTINGS.EMAIL'), onChange: setEmail, value: email }]
      .map(({ title, onChange, value }) => (
        <div key={title} className="setting-form-group">
          <div className="setting-input-title">{title}</div>
          <input className="setting-input" onChange={({ target: { value } }) => onChange(value)} value={value} required />
        </div>
      ))}
    <button type="submit" className="primary-button update-button">
      {i18next.t('SETTINGS.UPDATE_BTN')}
    </button>
  </form>
);

ProfileSetting.propTypes = {
  blob: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  setImage: PropTypes.func.isRequired,
  setName: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
};
ProfileSetting.defaultProps = {};
