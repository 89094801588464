import axios from 'axios';
import { useStoreActions, useStoreState } from 'easy-peasy';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';

import { API_ROUTE } from '../../apiRoutes';
import { NotificationBell } from '../../icons';
import { NotificationDialog } from '../common';

const SuperNavbarComponent = ({ title }) => {
  const notificationContainer = useRef();
  const [notification, setNotification] = useState(false);
  const logout = useStoreActions((actions) => actions.logOut);
  const addNotifications = useStoreActions((actions) => actions.addNotifications);
  const notifications = useStoreState(({ notifications }) => notifications);

  const fetchNotifications = () => axios.get(API_ROUTE.NOTIFICATIONS_FETCH)
    .then(({ data }) => {
      addNotifications(data.data);
    })
    .catch((err) => {
      console.log('notification failed', err);
    });

  useEffect(() => {
    if (notification) {
      fetchNotifications();
    }
  }, [notification]);
  useEffect(() => {
    fetchNotifications();
  }, []);

  const handleClickOutsideNotification = (event) => {
    if (notificationContainer.current && !notificationContainer.current.contains(event.target)) {
      setNotification(false);
    }
  };

  const handleOnScrollNotification = () => {
    if (notification) {
      setNotification(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideNotification);
    document.addEventListener('scroll', handleOnScrollNotification);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideNotification);
      document.removeEventListener('scroll', handleOnScrollNotification);
    };
  });

  return (
    <div className="admin-super-navbar">
      <div className="page-title-section">{title}</div>
      <div ref={notificationContainer} className="super-navbar-icons">
        <NotificationBell onClick={() => setNotification(!notification)} className="notification-bell" />
        <div className="log-out-node" onClick={logout}>Log Out</div>
        {notification && <NotificationDialog isAdmin notifications={notifications} />}
      </div>
    </div>
  );
};

SuperNavbarComponent.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};
SuperNavbarComponent.defaultProps = {};

export const SuperNavbar = withRouter(SuperNavbarComponent);
