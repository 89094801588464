import PropTypes from 'prop-types';
import React from 'react';

export const CommonTable = ({ headerList, children, bodyRef }) => (
  <div className="admin-common-table">
    <div className="common-table-header">
      {headerList.map((node) => <div key={node} className="table-head">{node}</div>)}
    </div>
    <div className="common-table-body" ref={bodyRef}>
      {children}
    </div>
  </div>
);

CommonTable.propTypes = {
  headerList: PropTypes.instanceOf(Array).isRequired,
  bodyRef: PropTypes.instanceOf(Object),
};
CommonTable.defaultProps = {
  bodyRef: {},
};
