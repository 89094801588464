import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

export const PasswordSetting = ({ setPassword, password, updateUser }) => (
  <form onSubmit={(e) => { e.preventDefault(); updateUser(); }}>
    <div className="profile-password">
      <div className="setting-form-group">
        <div className="setting-input-title">{i18next.t('SETTINGS.PASSWORD_LABEL')}</div>
        <div className="setting-password-container">
          <input
            type="password"
            className="setting-input password-input"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            required
          />
          <button
            type="submit"
            className="primary-button change-button"
          >
            {i18next.t('SETTINGS.PASS_CHANGE_BTN')}
          </button>
        </div>
      </div>
    </div>
  </form>
);

PasswordSetting.propTypes = {
  password: PropTypes.string.isRequired,
  setPassword: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
};
PasswordSetting.defaultProps = {};
