import PropTypes from 'prop-types';
import React from 'react';

export const BusinessShort = ({ data }) => data.map(({ title, value }) => (
  <div key={title} className="detail-block-dark">
    <div className="detail-title">{title}</div>
    <div className="detail-desc">{value}</div>
  </div>
));

BusinessShort.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
};
BusinessShort.defaultProps = {};
