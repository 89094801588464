import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

import { onlyNumberRange, onlyString } from '../../../utils';
import { FormInput } from '../../inputs';

export const EquityBlock = ({
  title, updateFormState, shareOwnership, partnershipAllocation, llcMembership, otherInterest,
  shareOwnershipKey, partnershipAllocationKey, llcMembershipKey, otherInterestKey,
}) => (
  <div className="form-section equity-intrest">
    <div className="section-title">{title}</div>
    <div className="section-content column-2x2">
      <FormInput
        label={i18next.t('EQUITY_BLOCK.TITLE1')}
        placeholder={i18next.t('EQUITY_BLOCK.TITLE1')}
        description={i18next.t('EQUITY_BLOCK.TITLE1_DESCRIPTION')}
        value={shareOwnership}
        onChange={(e) => updateFormState({
          [shareOwnershipKey]: onlyNumberRange(e.target.value, 100),
        })}
        required
      />
      <FormInput
        label={i18next.t('EQUITY_BLOCK.TITLE2')}
        placeholder={i18next.t('EQUITY_BLOCK.TITLE2')}
        description={i18next.t('EQUITY_BLOCK.TITLE2_DESCRIPTION')}
        value={partnershipAllocation}
        onChange={(e) => updateFormState({
          [partnershipAllocationKey]: onlyNumberRange(e.target.value, 100),
        })}
        required
      />
      <FormInput
        label={i18next.t('EQUITY_BLOCK.TITLE3')}
        placeholder={i18next.t('EQUITY_BLOCK.TITLE3')}
        description={i18next.t('EQUITY_BLOCK.TITLE3_DESCRIPTION')}
        value={llcMembership}
        onChange={(e) => updateFormState({
          [llcMembershipKey]: onlyNumberRange(e.target.value, 100),
        })}
        required
      />
      <FormInput
        label={i18next.t('EQUITY_BLOCK.TITLE4')}
        placeholder={i18next.t('EQUITY_BLOCK.TITLE4')}
        value={otherInterest}
        onChange={(e) => updateFormState({
          [otherInterestKey]: onlyString(e.target.value, 50),
        })}
        required
      />
    </div>
  </div>
);

EquityBlock.propTypes = {
  title: PropTypes.string.isRequired,
  llcMembership: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  otherInterest: PropTypes.string.isRequired,
  partnershipAllocation: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  shareOwnership: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  updateFormState: PropTypes.func.isRequired,
  shareOwnershipKey: PropTypes.string.isRequired,
  partnershipAllocationKey: PropTypes.string.isRequired,
  llcMembershipKey: PropTypes.string.isRequired,
  otherInterestKey: PropTypes.string.isRequired,
};
EquityBlock.defaultProps = {};
