import PropTypes from 'prop-types';
import React from 'react';

import { timeSince } from '../../utils';
import { CompanyLogo } from './CompanyLogo';

export const NotificationDialog = ({ isAdmin, notifications }) => (
  <div className="notification-dialog">
    <div className="notification-title">Notification</div>
    <div className="notification-list">
      {[...notifications].reverse().map(({
        _id, title, message, createdAt,
      }) => (
        <div key={_id} className="notification-list-item">
          <div className="notification-list-item-header">
            <div className={`notification-list-item-name ${!isAdmin ? 'user-notification-dialog' : ''}`}>
              <CompanyLogo title={title} />
            </div>
            <div className="notification-list-item-time">{timeSince(createdAt)}</div>
          </div>
          <div className="notification-list-item-description">{message}</div>
        </div>
      ))}
    </div>
  </div>
);

NotificationDialog.propTypes = {
  isAdmin: PropTypes.bool,
  notifications: PropTypes.instanceOf(Array).isRequired,
};
NotificationDialog.defaultProps = {
  isAdmin: false,
};
