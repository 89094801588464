import PropTypes from 'prop-types';
import * as React from 'react';

function SvgComponent({ percentage, ...props }) {
  const radius = 32;
  const circumference = radius * 2 * Math.PI;
  const strokeDasharray = `${circumference} ${circumference}`;
  const strokeDashoffset = circumference - ((percentage / 100) * circumference);
  return (
    <svg width={80} height={80} viewBox="0 0 80 80" fill="none" {...props}>
      <circle
        cx={40}
        cy={40}
        r={radius}
        stroke="#fff"
        strokeOpacity={0.497}
        strokeWidth={8}
      />
      <circle
        r={radius}
        cx={40}
        cy={40}
        className="progress-ring__circle"
        stroke="#fff"
        strokeWidth={12}
        fill="transparent"
        style={{ strokeDasharray, strokeDashoffset, transition: '0.35s stroke-dashoffset' }}
        transform="rotate(270 40 40)"
      />
    </svg>
  );
}

SvgComponent.propTypes = {
  percentage: PropTypes.number,
};

SvgComponent.defaultProps = {
  percentage: 30,
};

export default SvgComponent;
