import axios from 'axios';
import { useStoreActions } from 'easy-peasy';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import { API_ROUTE } from '../../../apiRoutes';
import { BigImageIcon, CloudIcon } from '../../../icons';
import { ControlButton } from '../../forms/ControlButton';

export const BoosterUpload = ({ onCancle, onUpload }) => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fileName, setFileName] = useState('');
  const pushToast = useStoreActions((actions) => actions.pushToast);
  const requestToken = useRef(axios.CancelToken.source());
  const uploadInput = useRef();

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const file = uploadInput.current.files[0];
    formData.append('file', file);
    setUploading(true);
    setProgress(0);
    axios.post(API_ROUTE.BOOSTER_UPLOAD_SEND, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (p) => { setProgress((p.loaded / p.total) * 100); },
      cancelToken: requestToken.current.token,
    })
      .then(() => {
        onUpload();
      })
      .catch((error) => {
        const { response, message } = error;
        if (response) {
          pushToast({ data: response.data.message });
        } else {
          pushToast({ data: message, type: 'error' });
        }
        setUploading(false);
        uploadInput.current.value = '';
      });
  };

  const addFileName = (e) => {
    const { files } = e.target;
    if (files.length) {
      setFileName(files[0].name);
    } else {
      setFileName('');
    }
  };

  const onPrev = () => {
    requestToken.current.cancel();
    onCancle();
  };

  return (
    <form method="post" onSubmit={onSubmit} className="booster-upload-container">
      <div className="upload-container">
        {!uploading ? (
          <div className="upload-click-container">
            <input
              ref={uploadInput}
              type="file"
              onChange={addFileName}
              className="hidden-file-input"
              required
            />
            <CloudIcon className="cloud-icon" />
            <div className="drag-text">{fileName || i18next.t('BOOSTER.DRAG_TEXT')}</div>
            {!fileName && (
              <div className="browse-files">
                {`${i18next.t('BOOSTER.OR')} `}
                <span>{i18next.t('BOOSTER.BROWSE')}</span>
                {` ${i18next.t('BOOSTER.YOUR_FILES')}`}
              </div>
            )}
          </div>
        ) : (
          <div className="uploading-container">
            <BigImageIcon />
            <div className="upload-progress-bar">
              <div className="progress" style={{ width: `${progress}%` }} />
            </div>
          </div>
        )}
      </div>
      <ControlButton
        nextTitle={i18next.t('BOOSTER.UPLOAD_BTN')}
        prevTitle={i18next.t('BOOSTER.CANCEL_BTN')}
        buttonPropsNext={{ type: 'submit' }}
        onPrev={onPrev}
      />
    </form>
  );
};

BoosterUpload.propTypes = {
  onCancle: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
};
BoosterUpload.defaultProps = {};
