import axios from 'axios';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { isEmpty } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import { API_ROUTE } from '../../apiRoutes';
import { BussinessProfileForm } from './bussinessProfile';
import { DirectorsForm } from './DirectorsForm';
import { FinalSubmissionForm } from './FinalSubmissionForm';
import { FormSidebar } from './FormSidebar';
import { LoanRequest } from './loanRequest';
import { ManagementForm } from './ManagementForm';
import { OwnershipForm } from './OwnershipForm';

const sidebarDATA = [
  { title: 'Loan Request', FormScreen: LoanRequest, key: 'loanRequest' },
  { title: 'Business Profile', FormScreen: BussinessProfileForm, key: 'businessProfile' },
  { title: 'Ownership', FormScreen: OwnershipForm, key: 'ownership' },
  { title: 'Directors', FormScreen: DirectorsForm, key: 'directors' },
  { title: 'Management', FormScreen: ManagementForm, key: 'management' },
  { title: 'Final Submission', FormScreen: FinalSubmissionForm, key: 'certifications' },
];

const initialFormState = {
  loanRequest: {
    purpose: [],
    meritAmount: '',
    meritTerm: '',
    meritReserver: '',
  },
  businessProfile: {
    legalName: '',
    businessAlias: '',
    taxID: '',
    industry: '',
    naicsCode: '',
    organizedYear: '',
    stateRegNo: '',
    orgState: '',
    orgStateDoc: '',
    goodStanding: '',
    goodStandingDoc: '',
    businessEntity: '',
    businessEntityOther: '',
    corporationDoc: '',
    partnershipAgreementDoc: '',
    operatingAgreementDoc: '',
    trustAgreementDoc: '',
    otherDoc: '',
    taxClassification: '',
    taxClassificationOther: '',
    businessPrimaryAdd: '',
    businessRegisteredAdd: '',
    phone: '',
    fax: '',
    email: '',
    annualRevenueLtm: '',
    annualRevenuePriorYear: '',
    annualProfitLtm: '',
    annualProfitPriorYear: '',
    annualRevenueLtmOther: '',
    annualProfitLtmOther: '',
    financialStatementDoc: '',
    financialStatementOptionalDoc: '',
    primaryBank: '',
    outstandingDebt: '',
    availableCash: '',
    bankStatement: '',
  },
  ownership: {
    owner: '',
    ownerType: '',
    ownerName: '',
    ownerAddress: '',
    shareOwnership: '',
    llcMembership: '',
    partnershipAllocation: '',
    otherInterest: '',
    ownerId: '',
    ownerIdType: '',
    ownerIdDoc: '',
  },
  directors: {
    directorName: '',
    directorTitle: '',
    directorAddress: '',
    directorshareOwnership: '',
    directorpartnershipAllocation: '',
    directorllcMembership: '',
    directorotherInterest: '',
    directorId: '',
    directorIdType: '',
    directorIdDoc: '',
  },
  management: {
    managerName: '',
    managerTitle: '',
    managerAddress: '',
    managershareOwnership: '',
    managerllcMembership: '',
    managerpartnershipAllocation: '',
    managerotherInterest: '',
    managerId: '',
    managerIdType: '',
    managerIdDoc: '',
  },
  certifications: {
    certificateOne: '',
    certificateOneDetail: '',
    certificateTwo: '',
    certificateTwoDetail: '',
    ndaDoc: '',
  },
};

const formReducer = (state = initialFormState, action) => ({
  ...state, [action.type]: { ...state[action.type], ...action.payload },
});

export const Form = ({ push }) => {
  const [activeSidebar, setActiveSidebar] = useState(sidebarDATA[0].title);
  const [formState, dispatch] = useReducer(formReducer, initialFormState);
  const updateUserApplication = useStoreActions((actions) => actions.updateUserApplication);
  const applicationData = useStoreState(({ userApplication }) => userApplication);
  const pushToast = useStoreActions((actions) => actions.pushToast);

  const next = (index) => {
    setActiveSidebar(sidebarDATA[index + 1].title);
  };
  const prev = (index) => {
    setActiveSidebar(sidebarDATA[index - 1].title);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [activeSidebar]);

  useEffect(() => {
    if (!isEmpty(applicationData)) {
      if (applicationData.status === 'approved') push('/dashboard');
      else if (applicationData.status === 'waiting') push('/review');
      else if (applicationData.status === 'declined') push('/rejected');
    }
    ReactTooltip.rebuild();
  }, []);

  const updateState = (type, payload) => {
    dispatch({ type, payload });
  };

  const onSubmit = () => {
    axios.post(API_ROUTE.FORM_CREATE, formState)
      .then(({ data }) => {
        pushToast({ data: data.message });
        updateUserApplication(data.data);
        push('/review');
      })
      .catch((error) => {
        const { response, message } = error;
        if (response) {
          pushToast({ data: response.data.message, type: 'error' });
        } else {
          pushToast({ data: message, type: 'error' });
        }
      });
  };

  return (
    <div className="merit-form-container">
      <div className="merit-form">
        <FormSidebar
          active={activeSidebar}
          data={sidebarDATA.map(({ title }) => title)}
          onChange={(activeSidebar) => setActiveSidebar(activeSidebar)}
        />
        <div className="merit-form-section">
          {sidebarDATA.map(({ title, FormScreen, key }, index) => (
            <FormScreen
              key={title}
              active={activeSidebar === title}
              next={() => next(index)}
              prev={() => prev(index)}
              onSubmit={onSubmit}
              updateState={updateState}
              data={formState[key]}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

Form.propTypes = {};
Form.defaultProps = {};
