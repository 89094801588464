import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { numberToAmount, numberToMonth } from '../../../../utils';
import {
  CompanyLogo, ScoreNode, StatusNode,
} from '../../../common';

export const ApplicationListItem = ({
  _id, legalName, meritAmount, meritTerm, status,
}) => (
  <Link to={`/admin-dashboard/applications/${_id}`} key={legalName + meritAmount} className="common-list-item">
    <div className="common-list-column">
      <CompanyLogo title={legalName} />
    </div>
    <div className="common-list-column table-text-dark light">{numberToAmount({ number: meritAmount })}</div>
    <div className="common-list-column table-text-dark light">{numberToMonth(meritTerm)}</div>
    <div className="common-list-column">
      <ScoreNode score={['bad', 'fair', 'good', 'excellent'][Math.floor((Math.random() * 4))]} />
    </div>
    <div className="common-list-column">
      <StatusNode mode={status} />
    </div>
  </Link>
);

ApplicationListItem.propTypes = {
  _id: PropTypes.string.isRequired,
  legalName: PropTypes.string.isRequired,
  meritAmount: PropTypes.string.isRequired,
  meritTerm: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};
ApplicationListItem.defaultProps = {};
