import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={64} height={64} viewBox="0 0 64 64" fill="none" {...props}>
      <rect width={64} height={64} rx={8} fill="#E8EAF6" />
      <path
        d="M32.64 43.808v-2.592c1.392-.224 2.484-.776 3.276-1.656.792-.88 1.188-1.896 1.188-3.048 0-1.12-.368-2.092-1.104-2.916-.736-.824-1.832-1.396-3.288-1.716l-2.184-.504c-1.104-.24-1.656-.776-1.656-1.608 0-.512.212-.952.636-1.32.424-.368 1.004-.552 1.74-.552.88 0 1.532.232 1.956.696.424.464.684.976.78 1.536l2.952-.84c-.192-.944-.64-1.796-1.344-2.556-.704-.76-1.688-1.252-2.952-1.476v-2.64h-2.448v2.568c-1.312.208-2.396.752-3.252 1.632-.856.88-1.284 1.944-1.284 3.192 0 1.136.372 2.1 1.116 2.892.744.792 1.772 1.332 3.084 1.62l2.208.48c1.2.256 1.8.824 1.8 1.704 0 .544-.22.98-.66 1.308-.44.328-1.044.492-1.812.492-1.04 0-1.84-.276-2.4-.828-.56-.552-.88-1.244-.96-2.076l-2.976.72c.128 1.152.628 2.208 1.5 3.168s2.084 1.544 3.636 1.752v2.568h2.448z"
        fill="#323232"
      />
    </svg>
  );
}

export default SvgComponent;
