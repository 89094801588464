import { useStoreState } from 'easy-peasy';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';

import { Layout, LoanApproved } from '../components';

export const LoanApprovedPage = ({ history: { push } }) => {
  const applicationData = useStoreState(({ userApplication }) => userApplication);
  useEffect(() => {
    if (!isEmpty(applicationData)) {
      if (applicationData.status === 'waiting') push('/review');
      else if (applicationData.status === 'declined') push('/rejected');
    } else {
      push('/loan-form');
    }
  }, []);
  return (
    <Layout>
      <LoanApproved onClick={() => push('/dashboard/home')} application={applicationData} />
    </Layout>
  );
};

LoanApprovedPage.propTypes = {};
LoanApprovedPage.defaultProps = {};
