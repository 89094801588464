import PropTypes from 'prop-types';
import React from 'react';

import { BackArrowIcon } from '../../../../icons';
import { StatusNode } from '../../../common';

export const NavTitle = ({ push, status, name }) => (
  <div className="loan-page-title" onClick={() => push('/admin-dashboard/applications')}>
    <BackArrowIcon className="back-arrow" />
    <div className="title">{name}</div>
    <StatusNode mode={status} />
  </div>
);

NavTitle.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};
NavTitle.defaultProps = {};
