import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

import { CardBubble, CCIcon } from '../../../icons';

export const CreditCard = ({
  id, card, billingDetails, onClick, selected, removeCard,
}) => {
  const { last4 } = card;
  const { name } = billingDetails;

  return (
    <div className="credit-preview-card-container" onClick={() => onClick(id)}>
      <div className={`credit-preview-card ${selected === id ? 'selected' : ''}`}>
        <CardBubble className="card-bubbles" />
        <div className="card-number-container">
          <CCIcon />
          <div className="card-detail">{`xxxx ${last4}`}</div>
        </div>
        <div className="card-detail" title={name}>{name}</div>
      </div>
      <div className="credit-preview-controls">
        <div className="control-node" onClick={(e) => { e.stopPropagation(); removeCard(id); }}>
          {i18next.t('DASHBOARD.CREDIT_CARD.REMOVE_CARD')}
        </div>
      </div>
    </div>
  );
};

CreditCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  billingDetails: PropTypes.instanceOf(Object).isRequired,
  card: PropTypes.PropTypes.instanceOf(Object).isRequired,
  removeCard: PropTypes.func.isRequired,
};
CreditCard.defaultProps = {};
