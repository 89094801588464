import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

const TEXT_MAP = () => ({
  bad: i18next.t('SCORE_NODE.BAD'),
  fair: i18next.t('SCORE_NODE.FAIR'),
  good: i18next.t('SCORE_NODE.GOOD'),
  excellent: i18next.t('SCORE_NODE.EXCELLENT'),
});

export const ScoreNode = ({ score }) => (
  <div className={`score-node ${score}`}>
    <div className="score-ball" />
    <div className="table-text-dark score-text">{TEXT_MAP()[score]}</div>
  </div>
);

ScoreNode.propTypes = {
  score: PropTypes.string.isRequired,
};
ScoreNode.defaultProps = {};
