import {
  CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe,
} from '@stripe/react-stripe-js';
import axios from 'axios';
import { useStoreActions } from 'easy-peasy';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import { API_ROUTE } from '../../apiRoutes';
import { stripeStype } from '../../constants';
import { CrossIcon } from '../../icons';
import { onlyAlpha } from '../../utils';

const useOptions = () => {
  const options = useMemo(() => ({ style: stripeStype }));
  return options;
};

export const CardDialog = ({ onSave }) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [loading, setLoading] = useState(false);
  const [cn, setCn] = useState();
  const [cvv, setCVV] = useState();
  const [months, setMonths] = useState();
  const [name, setName] = useState('');
  const pushToast = useStoreActions((actions) => actions.pushToast);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!stripe || !elements || !name) {
      setLoading(false);
      return;
    }
    const { paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
      billing_details: { name },
    });
    axios.post(API_ROUTE.ADD_CREDIT_CARD, { paymentMethod })
      .then(({ data }) => {
        pushToast({ data: data.message });
        onSave();
      })
      .catch((error) => {
        const { response, message } = error;
        if (response) {
          pushToast({ data: 'CARD_NOT_ADDED' });
        } else {
          pushToast({ data: message, type: 'error' });
        }
        setLoading(false);
      });
  };

  return createPortal(
    <>
      <div className="black-backdrop" onClick={onSave} />
      <form onSubmit={handleSubmit} className="card-dialog-container">
        <CrossIcon className="cross-icon" onClick={onSave} />
        <div className="card-dialog-title">{i18next.t('CARD_DIALOG.ADD_NEW_CARD')}</div>
        <div className="card-input-group">
          <div className="input-label">{i18next.t('CARD_DIALOG.CARD_NUMBER')}</div>
          <CardNumberElement
            className="dashboard-input"
            options={options}
            onChange={(e) => setCn(e.complete)}
          />
          <input className="hidden-card-input" tabIndex={-1} required={!cn} />
        </div>
        <div className="dialog-column-cvv">
          <div className="card-input-group">
            <div className="input-label">{i18next.t('CARD_DIALOG.MONTH')}</div>
            <div className="month-input-column">
              <CardExpiryElement
                className="dashboard-input"
                options={options}
                onChange={(e) => setMonths(e.complete)}
              />
              <input className="hidden-card-input" tabIndex={-1} required={!months} />
            </div>
          </div>
          <div className="card-input-group">
            <div className="input-label">{i18next.t('CARD_DIALOG.CVV')}</div>
            <div className="dialog-column-2 month-input-column">
              <CardCvcElement
                className="dashboard-input"
                options={options}
                onChange={(e) => setCVV(e.complete)}
              />
              <input className="hidden-card-input" tabIndex={-1} required={!cvv} />
            </div>
          </div>
        </div>
        <div className="card-input-group">
          <div className="input-label">{i18next.t('CARD_DIALOG.CARD_HOLDER_NAME')}</div>
          <input
            className="dashboard-input"
            onChange={({ target: { value } }) => setName(onlyAlpha(value, 35))}
            value={name}
            required
          />
        </div>
        <button
          type="submit"
          className="green-button card-dialog-button"
          disabled={loading}
        >
          {i18next.t('CARD_DIALOG.SAVE')}
        </button>
      </form>
    </>,
    document.body,
  );
};

CardDialog.propTypes = {
  onSave: PropTypes.func.isRequired,
};
CardDialog.defaultProps = {};
