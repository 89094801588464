import i18next from 'i18next';
import React from 'react';

import { LoadingIcon } from '../icons';

export const UnderReview = () => (
  <div className="under-review-container">
    <div className="under-review">
      <LoadingIcon className="review-icon" />
      <div className="review-text">{i18next.t('UNDER_REVIEW')}</div>
    </div>
  </div>
);

UnderReview.propTypes = {};
UnderReview.defaultProps = {};
