import PropTypes from 'prop-types';
import React from 'react';

import { ApplicationDetails } from './applicationDetails';
import { ApplicationTable } from './applicationTable';

export const ApplicationPage = ({ loanId, push, applications }) => (
  <>
    {loanId
      ? <ApplicationDetails push={push} applications={applications} loanId={loanId} />
      : <ApplicationTable push={push} applications={applications} />}
  </>
);

ApplicationPage.propTypes = {
  loanId: PropTypes.string,
  applications: PropTypes.instanceOf(Array),
};
ApplicationPage.defaultProps = {
  loanId: '',
  applications: [],
};
