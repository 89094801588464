import axios from 'axios';
import { useStoreActions } from 'easy-peasy';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { API_ROUTE } from '../apiRoutes';
import { Logo } from '../icons';
import { ErrorBlock } from './common';
import { FormInput } from './inputs';

export const Login = ({ push, isLogin }) => {
  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');
  const [error, setError] = useState('');
  const storeUserData = useStoreActions((actions) => actions.storeUserData);
  const updateUserApplication = useStoreActions((actions) => actions.updateUserApplication);
  const pushToast = useStoreActions((actions) => actions.pushToast);
  const showError = (error) => {
    setError(error);
    setTimeout(() => setError(''), 4000);
  };

  const signupSubmit = (e) => {
    e.preventDefault();
    axios.post(API_ROUTE.USER_SIGNUP, { email, password: pwd })
      .then(({ data }) => {
        pushToast({ data: data.message });
        push('/login');
      })
      .catch((error) => {
        const { response, message } = error;
        if (response) {
          showError(i18next.t(`ERRORS.${response.data.message}`));
        } else {
          showError(message);
        }
        setEmail('');
        setPwd('');
      });
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    axios.post(API_ROUTE.USER_SIGNIN, {
      email,
      password: pwd,
    })
      .then(({ data }) => {
        storeUserData({ token: data.token, user: data.user });
        if (data.application) { // if exist then normal user with submitted application
          const { application } = data;
          const { status } = application;
          updateUserApplication(application);
          if (status === 'waiting') {
            push('/review');
          } else if (status === 'declined') {
            push('/rejected');
          } else if (status === 'approved') {
            if (data.user.visitedApproved === true) {
              push('/dashboard');
            } else {
              push('/approved');
            }
          }
        }
      })
      .catch((error) => {
        const { response, message } = error;
        if (response) {
          showError(i18next.t(`ERRORS.${response.data.message}`));
        } else {
          showError(message);
        }
        setEmail('');
        setPwd('');
      });
  };
  return (
    <div className="authentication-component">
      <Logo className="logo-svg" />
      <ErrorBlock className={`authentication-error ${error ? 'show' : ''}`} error={error} />
      <div className="authentication-block">
        <div className="authentication-title">{isLogin ? i18next.t('LOGIN.LOG_IN') : i18next.t('LOGIN.SIGN_UP')}</div>
        <form method="post" onSubmit={isLogin ? loginSubmit : signupSubmit} className="authentication-form">
          <FormInput
            type="email"
            name="email"
            label={i18next.t('LOGIN.EMAIL_ADDRESS')}
            placeholder={i18next.t('LOGIN.EMAIL_ADDRESS')}
            value={email}
            onChange={({ target: { value } }) => setEmail(value)}
            required
            autoFocus
          />
          <FormInput
            type="password"
            name="password"
            label={i18next.t('LOGIN.PASSWORD')}
            placeholder={i18next.t('LOGIN.PASSWORD')}
            value={pwd}
            onChange={({ target: { value } }) => setPwd(value)}
            pattern="[^\s]{0,}"
            title={i18next.t('LOGIN.PASSWORD_TITLE')}
            required
          />
          <button
            type="submit"
            className="primary-button authentication-submit"
          >
            {isLogin ? i18next.t('LOGIN.LOG_IN') : i18next.t('LOGIN.CREATE_ACCOUNT') }
          </button>
          <div className="authentication-agreement">
            {isLogin ? i18next.t('LOGIN.LOG_IN_DESCRIPTION') : i18next.t('LOGIN.SIGN_UP_DESCRIPTION')}
          </div>
        </form>
        <Link to={isLogin ? '/signup' : '/login'} className="authentication-switch">
          {isLogin ? i18next.t('LOGIN.DONT_HAVE_ACCOUNT') : i18next.t('LOGIN.HAVE_ACCOUNT')}
        </Link>
      </div>
    </div>
  );
};

Login.propTypes = {
  isLogin: PropTypes.bool,
};
Login.defaultProps = {
  isLogin: false,
};
