import { useStoreState } from 'easy-peasy';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';

import { Layout } from '../components';
import { Booster, Home } from '../components/dashboard';

export const DashboardPage = ({
  history: { replace },
  match: { params: { page } },
}) => {
  const applicationData = useStoreState(({ userApplication }) => userApplication);
  useEffect(() => {
    if (!isEmpty(applicationData)) {
      if (applicationData.status === 'waiting') replace('/review');
      else if (applicationData.status === 'declined') replace('/rejected');
    } else {
      replace('/loan-form');
    }
  });
  return (
    <Layout>
      <div className="dashboard-screen-container">
        {page.toLowerCase() === 'home' && <Home />}
        {page.toLowerCase() === 'booster' && <Booster />}
        {page.toLowerCase() === 'external' && (
        <div className="coming-soon">
          Coming Soon!
          <span aria-label="builder" role="img">👷</span>
        </div>
        )}
      </div>
    </Layout>
  );
};

DashboardPage.propTypes = {};
DashboardPage.defaultProps = {};
