import axios from 'axios';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useEffect, useRef } from 'react';

import { API_ROUTE } from '../apiRoutes';
import { Layout, UnderReview } from '../components';

export const UnderReviewPage = ({ history: { replace } }) => {
  const intervalId = useRef(null);
  const applicationData = useStoreState(({ userApplication }) => userApplication);
  const updateUserApplication = useStoreActions((actions) => actions.updateUserApplication);
  const pushToast = useStoreActions((actions) => actions.pushToast);

  const checkReview = () => axios.get(API_ROUTE.FORM_FETCH)
    .then(({ data }) => {
      updateUserApplication(data.data);
      if (data.data.status === 'declined') {
        replace('/rejected');
      } else if (data.data.status === 'approved') {
        replace('/approved');
      }
    })
    .catch((error) => {
      const { response, message } = error;
      if (response) {
        pushToast({ data: response.data.message, type: 'error' });
      } else {
        pushToast({ data: message, type: 'error' });
      }
    });

  useEffect(() => {
    if (applicationData.status === 'approved') {
      replace('/dashboard');
    } if (applicationData.status === 'declined') {
      replace('/rejected');
    } else {
      checkReview();
      intervalId.current = setInterval(() => {
        checkReview();
      }, 15000);
    }
    return () => clearInterval(intervalId.current);
  }, []);

  return (
    <Layout>
      <UnderReview />
    </Layout>
  );
};

UnderReviewPage.propTypes = {};
UnderReviewPage.defaultProps = {};
