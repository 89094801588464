import PropTypes from 'prop-types';
import React from 'react';

export const BusinessSummary = ({ data }) => (
  <div className="application-bussiness-details">
    {data.map(({ title, value, Icon }) => (
      <div key={title} className="detail-block">
        <div className="text-section">
          <div className="detail-title">{title}</div>
          <div className="detail-desc" title={value}>{value}</div>
        </div>
        {Icon && Icon}
      </div>
    ))}
  </div>
);

BusinessSummary.propTypes = {
  data: PropTypes.instanceOf(Array).isRequired,
};
BusinessSummary.defaultProps = {};
