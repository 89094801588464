import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

import { ID_TYPE_DATA } from '../../../data';
import { RadioInput, UploadInput } from '../../inputs';

export const OwnerEquityID = ({
  name, updateFormState, ownerIdType, ownerIdTypeKey, ownerIdDocKey,
}) => (
  <div className="form-section owner-identification-files">
    <div className="section-content">
      <div className="owner-identification-files-radio">
        {ID_TYPE_DATA().map(({ title, value }) => (
          <RadioInput
            key={title}
            name={name}
            title={title}
            value={value}
            selected={ownerIdType}
            onChange={(value) => updateFormState({ [ownerIdTypeKey]: value })}
            required
          />
        ))}
      </div>
      <div className="owner-identification-files-upload">
        <div className="upload-text">
          {i18next.t('ID_DOC_BLOCK.TEXT1')}
        </div>
        <UploadInput onUpload={(url) => updateFormState({ [ownerIdDocKey]: url })} required />
      </div>
    </div>
  </div>
);
OwnerEquityID.propTypes = {
  name: PropTypes.string.isRequired,
  ownerIdDocKey: PropTypes.string.isRequired,
  ownerIdType: PropTypes.string.isRequired,
  ownerIdTypeKey: PropTypes.string.isRequired,
  updateFormState: PropTypes.func.isRequired,
};
OwnerEquityID.defaultProps = {};
