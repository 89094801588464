import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path opacity={0.01} stroke="#979797" d="M.5.5h23v23H.5z" />
      <g
        opacity={0.505}
        stroke="#000"
        strokeWidth={1.4}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx={12} cy={12} r={7} />
        <path d="M9.963 9.9a2.1 2.1 0 014.08.7c0 1.4-2.1 2.1-2.1 2.1M12 15.5h.007" />
      </g>
    </svg>
  );
}

export default SvgComponent;
