import i18next from 'i18next';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

import { CirclePercent } from '../../../icons';
import { intrestCalculator, numberToAmount, onTimePayment } from '../../../utils';
import { PaymentHistory } from '../booster/PaymentHistory';
import { YourPlan } from './YourPlan';

export const HomeRightSection = ({
  application, getPaymentHistory, successfullPayments, unsuccessfullPayments, paidAmount,
}) => {
  const { loanRequest: { meritAmount, meritTerm }, interest } = !isEmpty(application)
    ? application : { loanRequest: {} };
  const nextPaymentDate = unsuccessfullPayments[0] || { installmentDate: new Date() };
  const totalAmount = Number(intrestCalculator(meritAmount, interest, meritTerm));
  const remainingDays = moment(nextPaymentDate.installmentDate).diff(moment(), 'days');
  return (
    <div className="right-section">
      <div className="loan-details-section">
        <div className="amount-detail-card">
          <div className="title">{i18next.t('DASHBOARD.BUILDER_PAYMENTS.AMOUNT_REMAINING')}</div>
          <div className="amount">{numberToAmount({ number: totalAmount - paidAmount, limit: true })}</div>
        </div>
        <div className="amount-detail-card">
          <div className="title">{i18next.t('DASHBOARD.BUILDER_PAYMENTS.TOTAL_LOAN')}</div>
          <div className="amount">{numberToAmount({ number: totalAmount, limit: true })}</div>
        </div>
        <div className="on-time-card">
          <div className="on-time-title">{i18next.t('DASHBOARD.BUILDER_PAYMENTS.ON_TIME_PAYMENT')}</div>
          <CirclePercent
            className="circle-percent"
            percent={Math.ceil(onTimePayment(successfullPayments)) || 0}
          />
        </div>
        {successfullPayments.length !== Number(meritTerm)
          ? (
            <div className="next-payment-card">
              <div className="next-payment-title">{i18next.t('DASHBOARD.BUILDER_PAYMENTS.NEXT_PAYMENT')}</div>
              <div className="next-payment-date">{moment(nextPaymentDate.installmentDate).format('D MMM, YYYY')}</div>
              <div className={`next-payment-remaining ${remainingDays <= 0 ? 'overdue' : ''}`}>
                <span>{`${Math.abs(remainingDays)} `}</span>
                {remainingDays >= 0
                  ? i18next.t('DASHBOARD.BUILDER_PAYMENTS.DAYS_REMAINING')
                  : i18next.t('DASHBOARD.BUILDER_PAYMENTS.DAYS_EXCEEDED')}
              </div>
            </div>
          ) : (
            <div className="next-payment-card">
              <div className="next-payment-title">{i18next.t('DASHBOARD.BUILDER_PAYMENTS.CONGRATULATIONS')}</div>
              <div className="next-payment-date">{i18next.t('DASHBOARD.BUILDER_PAYMENTS.LOAN_PAID')}</div>
            </div>
          )}
      </div>
      <div className="loan-details-history-section">
        <PaymentHistory
          payments={successfullPayments}
          getPaymentHistory={getPaymentHistory}
        />
        <YourPlan
          application={application}
        />
      </div>
    </div>
  );
};

HomeRightSection.propTypes = {
  application: PropTypes.instanceOf(Object),
  successfullPayments: PropTypes.instanceOf(Array),
  unsuccessfullPayments: PropTypes.instanceOf(Array),
  paidAmount: PropTypes.number.isRequired,
  getPaymentHistory: PropTypes.func,
};
HomeRightSection.defaultProps = {
  application: { loanRequest: { meritAmount: '' } },
  successfullPayments: [],
  unsuccessfullPayments: [],
  getPaymentHistory: () => {},
};
