import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={277} height={284} viewBox="0 0 277 284" {...props}>
      <defs>
        <filter id="prefix__a">
          <feColorMatrix
            in="SourceGraphic"
            values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
          />
        </filter>
      </defs>
      <g
        transform="translate(-207 59)"
        filter="url(#prefix__a)"
        fill="none"
        fillRule="evenodd"
        opacity={0.354}
      >
        <g stroke="#B0B0B0">
          <path
            d="M414.436 46.626c10.736 5.032 18.641 13.307 23.37 23.373 4.93 10.495 6.409 22.933 4.054 35.672-2.224 12.027-7.865 24.323-17.245 35.514-16.53 19.722-37.09 26.806-57.833 26.126-26.542-.87-53.376-14.426-72.493-30.486-17.665-14.84-30.015-37.886-33.833-61.392-3.54-21.793.254-43.993 14-60.393 6.08-7.255 11.436-9.807 16.745-9.338 6.918.612 13.695 6.241 21.757 12.87 14.77 12.147 33.74 27.71 65.151 23.481 14.298-1.925 26.497-.036 36.327 4.573z"
            opacity={0.167}
          />
          <path
            d="M398.315 42.236c9.757 2.635 17.618 8.41 23.076 16.16 5.693 8.083 8.774 18.31 8.68 29.358-.088 10.434-3.008 21.6-9.233 32.393-10.968 19.02-27.191 28.02-44.717 30.525-22.437 3.206-46.996-4.207-65.44-14.868-17.041-9.85-30.83-27.386-37.517-46.572-6.198-17.783-6.298-36.995 2.823-52.812 4.022-6.974 8.127-9.912 12.643-10.303 5.898-.512 12.418 3.216 20.163 7.585 14.236 8.03 32.515 18.31 58.332 10.081 11.725-3.736 22.25-3.96 31.19-1.547z"
            opacity={0.333}
          />
          <path
            d="M383.215 42.067c8.357.746 15.616 4.334 21.195 9.882 5.821 5.79 9.814 13.71 11.329 22.76 1.431 8.553.65 18.112-2.888 27.844-6.234 17.148-18.212 26.857-32.192 31.438-17.91 5.868-39.088 3.346-55.727-2.717-15.372-5.601-29.187-17.964-37.428-32.705-7.635-13.656-10.49-29.363-5.305-43.624 2.276-6.26 5.193-9.253 8.819-10.222 4.747-1.27 10.612.844 17.571 3.298 12.845 4.528 29.334 10.31 49.332-.182 9.054-4.75 17.633-6.455 25.294-5.772z"
            opacity={0.5}
          />
          <path
            d="M370 45.727c6.656-.572 12.843 1.237 17.984 4.815 5.367 3.736 9.593 9.398 12.033 16.29 2.306 6.516 3.015 14.13 1.583 22.262-2.524 14.326-10.584 23.608-20.927 29.142-13.261 7.096-30.253 8.05-44.168 5.594-12.85-2.269-25.414-10.066-33.927-20.503-7.883-9.664-12.3-21.599-10.2-33.514.914-5.194 2.77-7.94 5.466-9.197 3.546-1.651 8.436-.797 14.227.163 10.76 1.783 24.564 4.041 38.876-7.016 6.449-4.983 12.946-7.51 19.053-8.036z"
            opacity={0.667}
          />
          <path
            d="M360.65 53.195c4.818-1.273 9.599-.751 13.84 1.194 4.431 2.033 8.272 5.618 10.959 10.327 2.543 4.458 4.051 9.924 4.051 16.042 0 10.777-4.73 18.587-11.626 23.957-8.858 6.898-21.266 9.785-31.846 9.785-9.767 0-20.04-4.077-27.67-10.604-7.054-6.035-11.858-14.173-11.858-23.138 0-3.86.985-6.088 2.79-7.34 2.39-1.657 6.094-1.653 10.468-1.698 8.214-.086 18.744-.224 27.914-10.21 4.1-4.464 8.552-7.146 12.979-8.315z"
            opacity={0.833}
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
