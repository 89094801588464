import { useStoreActions, useStoreState } from 'easy-peasy';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

import { DollarIcon } from '../../../icons';
import { numberToAmount } from '../../../utils';
import { CoverLoader } from '../../common';
import { CreditCard } from './CreditCard';

export const MakePayment = ({
  setShowDialog, removeCard, submitPayment, installmentAmount, loading,
}) => {
  const setSelectedCreditCard = useStoreActions((actions) => actions.setSelectedCreditCard);
  const selectedCreditCard = useStoreState(({ selectedCreditCard }) => selectedCreditCard);
  const creditCardList = useStoreState(({ creditCardList }) => creditCardList);
  return (
    <div className="make-payment-section">
      <div className="make-payment-title block-title">
        {i18next.t('DASHBOARD.BUILDER_PAYMENTS.MAKE_PAYMENT')}
      </div>
      <div className="make-payment-content">
        {loading && <CoverLoader />}
        <div className="make-payment-input-group">
          <div className="make-payment-input-title">
            {i18next.t('DASHBOARD.BUILDER_PAYMENTS.AMOUNT')}
          </div>
          <div className="make-payment-input-content">
            <div className="amount-intrest">
              <DollarIcon />
              <div className="amount">
                {numberToAmount({ number: installmentAmount, limit: true, currency: '' })}
              </div>
            </div>
          </div>
        </div>
        <div className="make-payment-input-group">
          <div className="make-payment-input-title">
            {i18next.t('DASHBOARD.BUILDER_PAYMENTS.CARD_TITLE')}
          </div>
          <div className="make-payment-input-content">
            <div className="saved-cards">
              {creditCardList.map(({
                id, card, billing_details: billingDetails,
              }) => (
                <CreditCard
                  key={id}
                  id={id}
                  card={card}
                  billingDetails={billingDetails}
                  onClick={(id) => setSelectedCreditCard(id)}
                  selected={selectedCreditCard}
                  removeCard={removeCard}
                />
              ))}
              <div className="add-new-card" onClick={() => setShowDialog(true)}>
                {i18next.t('DASHBOARD.BUILDER_PAYMENTS.ADD_NEW_CARD')}
              </div>
            </div>
          </div>
        </div>
        <button onClick={submitPayment} type="button" className="green-button pay-now-button" disabled={loading}>
          {i18next.t('DASHBOARD.BUILDER_PAYMENTS.PAY_BTN')}
        </button>
      </div>
    </div>
  );
};

MakePayment.propTypes = {
  setShowDialog: PropTypes.func.isRequired,
  removeCard: PropTypes.func.isRequired,
  submitPayment: PropTypes.func.isRequired,
  installmentAmount: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
};
MakePayment.defaultProps = {};
