import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

import { HelpIcon } from '../../icons';
import { onlyNumbers, onlyString } from '../../utils/textInputValid';
import { FormInput } from '../inputs';
import { EquityBlock, OwnerEquityID } from './common';
import { ControlButton } from './ControlButton';

export const ManagementForm = ({
  active, next, prev, data, updateState,
}) => {
  const {
    managerName,
    managerTitle,
    managerAddress,
    managershareOwnership,
    managerllcMembership,
    managerpartnershipAllocation,
    managerotherInterest,
    managerId,
    managerIdType,
  } = data;
  const updateFormState = (payload) => updateState('management', payload);

  return (
    <form
      method="post"
      onSubmit={(e) => { e.preventDefault(); next(); }}
      className={`form-content ownership-director-management-container ${active ? 'active' : ''}`}
    >
      <div className="form-title">{i18next.t('MANAGEMENT.TITLE')}</div>
      <div className="form-text-title ownership-more-help">
        {i18next.t('MANAGEMENT.BLOCK1.TEXT')}
      </div>
      <div className="form-light-description board-directors-text">
        {i18next.t('MANAGEMENT.BLOCK1.HINT')}
      </div>
      <div className="form-section">
        <div className="section-block column-2">
          <FormInput
            label={i18next.t('MANAGEMENT.NAME.LABEL')}
            placeholder={i18next.t('MANAGEMENT.NAME.LABEL')}
            value={managerName}
            onChange={(e) => updateFormState({ managerName: onlyString(e.target.value, 50) })}
            required
          />
          <FormInput
            label={i18next.t('MANAGEMENT.NAME_TITLE.LABEL')}
            placeholder={i18next.t('MANAGEMENT.NAME_TITLE.LABEL')}
            value={managerTitle}
            onChange={(e) => updateFormState({ managerTitle: onlyString(e.target.value, 50) })}
            required
          />
        </div>
      </div>
      <div className="form-section">
        <div className="section-block">
          <FormInput
            label={i18next.t('MANAGEMENT.ADDRESS.LABEL')}
            placeholder={i18next.t('MANAGEMENT.ADDRESS.LABEL')}
            description={i18next.t('MANAGEMENT.ADDRESS.HINT')}
            value={managerAddress}
            onChange={(e) => updateFormState({ managerAddress: onlyString(e.target.value, 100) })}
            required
          />
        </div>
      </div>
      <EquityBlock
        title={i18next.t('MANAGEMENT.BLOCK2.TITLE')}
        updateFormState={updateFormState}
        shareOwnership={managershareOwnership}
        partnershipAllocation={managerpartnershipAllocation}
        llcMembership={managerllcMembership}
        otherInterest={managerotherInterest}
        shareOwnershipKey="managershareOwnership"
        partnershipAllocationKey="managerpartnershipAllocation"
        llcMembershipKey="managerllcMembership"
        otherInterestKey="managerotherInterest"
      />
      <div className="form-section owner-identification">
        <div className="section-title">
          {i18next.t('MANAGEMENT.MANAGER_ID.TITLE')}
          <HelpIcon
            className="help-icon"
            data-for="help-tooltip"
            data-tip={i18next.t('MANAGEMENT.MANAGER_ID.HINT')}
            data-iscapture="true"
            data-event="click focus"
            data-event-off="blur"
          />
        </div>
        <div className="section-block column-2">
          <FormInput
            label={i18next.t('MANAGEMENT.MANAGER_ID.TITLE')}
            placeholder={i18next.t('MANAGEMENT.MANAGER_ID.TITLE')}
            value={managerId}
            onChange={(e) => updateFormState({ managerId: onlyNumbers(e.target.value, 10) })}
            required
          />
        </div>
      </div>

      <OwnerEquityID
        name="manager-id-type"
        updateFormState={updateFormState}
        ownerIdType={managerIdType}
        ownerIdTypeKey="managerIdType"
        ownerIdDocKey="managerIdDoc"
      />

      <ControlButton
        nextTitle={i18next.t('BUTTONS.NEXT')}
        prevTitle={i18next.t('BUTTONS.PREVIOUS')}
        onPrev={prev}
        buttonPropsNext={{ type: 'submit' }}
      />
    </form>
  );
};
ManagementForm.propTypes = {
  next: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,
  updateState: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  active: PropTypes.bool.isRequired,
};
ManagementForm.defaultProps = {};
