import axios from 'axios';
import { useStoreActions } from 'easy-peasy';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { API_ROUTE } from '../../../../apiRoutes';
import { AdminLayout } from '../../AdminLayout';
import { DATA_MAP2 } from '../data';
import { ApplicationOverview } from './applicationOverview';
import { BusinessDetails } from './BusinessDetails';
import { NavTitle } from './NavTitle';

const APPLICATION_DETAIL_NAV = () => [
  {
    title: i18next.t('APPLICATION_DETAIL_NAV.NAV1'),
    value: 'overview',
  }, {
    title: i18next.t('APPLICATION_DETAIL_NAV.NAV2'),
    value: 'business_details',
  },
];

export const ApplicationDetails = ({ push, applications, loanId }) => {
  const updateApplication = useStoreActions((actions) => actions.updateApplication);
  const pushToast = useStoreActions((actions) => actions.pushToast);
  const [subNav, setSubNav] = useState(APPLICATION_DETAIL_NAV()[0].value);
  const [mid, setMid] = useState(1);
  const [newInterest, setNewInterest] = useState(13.4);
  const [isForm, setIsForm] = useState(false); // Used to fix form rerender issue
  const currentApplication = applications.filter(({ _id }) => loanId === _id)[0] || {
    businessProfile: {},
    loanRequest: {},
  };
  const [newMeritAmount, setNewMeritAmount] = useState(currentApplication.loanRequest.meritAmount);
  const { businessProfile, status } = currentApplication;
  const { legalName } = businessProfile;

  const approveApplication = (e) => {
    e.preventDefault();
    axios.put(API_ROUTE.FORM_UPDATE, {
      status: e.nativeEvent.submitter.name,
      monthlyInstallmentDate: mid,
      interest: newInterest,
      formId: loanId,
      newMeritAmount,
    })
      .then(({ data }) => {
        updateApplication(data);
        setIsForm(true);
        if (e.nativeEvent.submitter.name === 'approved') {
          pushToast({ data: 'APPLICATION_APPROVED' });
        } else {
          pushToast({ data: 'APPLICATION_DECLINED' });
        }
      })
      .catch((error) => {
        const { response, message } = error;
        if (response) {
          pushToast({ data: response.data.message });
        } else {
          pushToast({ data: message, type: 'error' });
        }
      });
  };

  return (
    <AdminLayout
      title={(<NavTitle name={legalName} push={push} status={status} />)}
      subNavData={APPLICATION_DETAIL_NAV()}
      subNavOnClick={setSubNav}
      subNavActive={subNav}
    >
      {subNav === APPLICATION_DETAIL_NAV()[0].value ? (
        <ApplicationOverview
          isForm={isForm}
          mid={mid}
          newInterest={newInterest}
          setNewInterest={setNewInterest}
          setMid={setMid}
          approveApplication={approveApplication}
          currentApplication={currentApplication}
          newMeritAmount={newMeritAmount}
          setNewMeritAmount={setNewMeritAmount}
        />
      ) : (
        <BusinessDetails data={DATA_MAP2(businessProfile)} />
      )}
    </AdminLayout>
  );
};

ApplicationDetails.propTypes = {
  applications: PropTypes.instanceOf(Array).isRequired,
  loanId: PropTypes.string.isRequired,
};
ApplicationDetails.defaultProps = {};
