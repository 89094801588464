import { useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { API_ROUTE } from '../../../apiRoutes';
import { intrestCalculator } from '../../../utils';
import { CardDialog } from '../../common';
import { HomeLeftSection } from './HomeLeftSection';
import { HomeRightSection } from './HomeRightSection';

export const Home = () => {
  const stripe = useStripe();
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const application = useStoreState(({ userApplication }) => userApplication);
  const { user } = useStoreState(({ auth }) => auth);
  const creditCardList = useStoreState(({ creditCardList }) => creditCardList);
  const addCards = useStoreActions((actions) => actions.addCards);
  const addPayments = useStoreActions((actions) => actions.addPayments);
  const pushToast = useStoreActions((actions) => actions.pushToast);
  const setSelectedCreditCard = useStoreActions((actions) => actions.setSelectedCreditCard);
  const payments = useStoreState((actions) => actions.payments);
  const selectedCreditCard = useStoreState(({ selectedCreditCard }) => selectedCreditCard);

  const successfullPayments = payments.filter(({ paymentIdStripe }) => paymentIdStripe);
  const unsuccessfullPayments = payments.filter(({ paymentIdStripe }) => !paymentIdStripe)
    .sort((a, b) => moment(a.installmentDate).unix() - moment(b.installmentDate).unix());
  const paidAmount = successfullPayments
    .map(({ amount }) => amount)
    .reduce((a, b) => a + b, 0);

  const { loanRequest: { meritAmount, meritTerm }, interest } = !isEmpty(application)
    ? application : { loanRequest: {} };
  const totalAmount = Number(intrestCalculator(meritAmount, interest, meritTerm));
  const installmentAmount = unsuccessfullPayments.length ? (totalAmount / meritTerm) : 0;

  const getPaymentHistory = (callback = () => {}) => {
    axios.get(API_ROUTE.GET_USER_PAYMENTS)
      .then(({ data }) => {
        addPayments(data.data);
        callback();
      })
      .catch((error) => {
        const { response, message } = error;
        if (response) {
          pushToast({ data: 'HISTORY_FAILED', type: 'error' });
        } else {
          pushToast({ data: message, type: 'error' });
        }
        callback();
      });
  };

  const getUserCards = () => {
    axios.get(API_ROUTE.GET_CREDIT_CARDS)
      .then(({ data: { data } }) => {
        if (!isEmpty(data)) {
          const currentCard = data.find(({ id }) => id === selectedCreditCard);
          if (!currentCard) setSelectedCreditCard(data[0].id);
        }
        addCards(data);
      })
      .catch((error) => {
        const { response, message } = error;
        if (response) {
          pushToast({ data: 'CARD_FETCH_ERROR', type: 'error' });
        } else {
          pushToast({ data: message, type: 'error' });
        }
      });
  };

  const removeCard = (id) => {
    axios.post(API_ROUTE.REMOVE_CREDIT_CARDS, { paymentMethodId: id })
      .then(() => {
        pushToast({ data: 'CARD_REMOVED' });
        getUserCards();
      }).catch((error) => {
        const { response, message } = error;
        if (response) {
          pushToast({ data: 'SOMETHING_WENT_WRONG', type: 'error' });
        } else {
          pushToast({ data: message, type: 'error' });
        }
      });
  };

  const confirmPayment = (clientSecret) => {
    stripe.confirmCardPayment(clientSecret, {
      payment_method: selectedCreditCard,
      setup_future_usage: 'off_session',
    }).then((result) => {
      if (result.error) {
        pushToast({ data: 'PAYMENT_FAILED', type: 'error' });
        setLoading(false);
      } else if (result.paymentIntent.status === 'succeeded') {
        pushToast({ data: 'PAYMENT_SUCCESS' });
      }
      [4, 7, 21, 35].map((time) => setTimeout(
        () => getPaymentHistory(() => setLoading(false)), time * 1000,
      ));
    })
      .catch((error) => {
        const { response, message } = error;
        if (response) {
          pushToast({ data: 'PAYMENT_FAILED', type: 'error' });
        } else {
          pushToast({ data: message, type: 'error' });
        }
        setLoading(false);
      });
  };

  const submitPayment = () => {
    if (!unsuccessfullPayments.length) {
      pushToast({ data: 'LOAN_ALREADY_COMPLETE' });
    } else if (!user.name) {
      pushToast({ data: 'ADD_NAME_SETTINGS', type: 'error' });
    } else if (!creditCardList.length) {
      pushToast({ data: 'CARD_MISSING', type: 'error' });
    } else if (!creditCardList.map(({ id }) => id).includes(selectedCreditCard)) {
      pushToast({ data: 'PLEASE_SELECT_CARD', type: 'error' });
    } else {
      setLoading(true);
      axios.post(API_ROUTE.MAKE_PAYMENT, { paymentMethod: selectedCreditCard })
        .then(({ data }) => {
          confirmPayment(data.paymentIntent.client_secret);
        })
        .catch((error) => {
          const { response, message } = error;
          if (response) {
            pushToast({ data: response.data.message, type: 'error' });
          } else {
            pushToast({ data: message, type: 'error' });
          }
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    getPaymentHistory();
  }, []);

  useEffect(() => {
    getUserCards();
  }, [showDialog]);

  const componentProps = {
    setShowDialog,
    removeCard,
    submitPayment,
    application,
    totalAmount,
    installmentAmount,
    payments,
    getPaymentHistory,
    successfullPayments,
    unsuccessfullPayments,
    paidAmount,
    loading,
  };

  return (
    <div className="builder-payments">
      {showDialog && <CardDialog onSave={() => setShowDialog(false)} />}
      <HomeLeftSection {...componentProps} />
      <HomeRightSection {...componentProps} />
    </div>
  );
};

Home.propTypes = {};
