import axios from 'axios';
import { useStoreActions } from 'easy-peasy';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { API_ROUTE } from '../apiRoutes';
import { PURPOSE_DATA } from '../data/loanRequestData';
import star from '../images/star.png';
import { numberToAmount, valueToTitle } from '../utils';

export const LoanApproved = ({ onClick, application }) => {
  const { interest, loanRequest: { meritTerm, purpose, meritAmount } } = application;
  const updateUserData = useStoreActions((actions) => actions.storeUserData);

  useEffect(() => {
    axios.post(API_ROUTE.USER_UPDATE, { visitedApproved: true })
      .then(({ data }) => {
        updateUserData({ user: data.user, token: data.token });
      })
      .catch(() => {
        console.log('Error updating approved status!');
      });
  }, []);

  return (
    <div className="loan-approved-container">
      <div className="loan-approved">
        <img alt="title" className="loan-icon" src={star} />
        <div className="loan-official">{i18next.t('LOAN_APPROVED.ITS_OFFICIAL')}</div>
        <div className="loan-text">
          {i18next.t('LOAN_APPROVED.LOAN_TEXT', { amount: numberToAmount({ number: meritAmount }) })}
        </div>
        <div className="loan-details">
          {[
            { title: i18next.t('LOAN_APPROVED.DETAILS.TITLE1'), value: valueToTitle(purpose, PURPOSE_DATA()).join(', ') },
            { title: i18next.t('LOAN_APPROVED.DETAILS.TITLE2'), value: i18next.t('LOAN_APPROVED.DETAILS.VALUE_MONTHS', { months: meritTerm }) },
            { title: i18next.t('LOAN_APPROVED.DETAILS.TITLE3'), value: `${interest}%` },
          ].map(({ title, value }) => (
            <div key={value} className="loan-detail-block">
              <div className="loan-detail-title">{title}</div>
              <div className="loan-detail-description">{value}</div>
            </div>
          ))}
        </div>
        <button
          type="button"
          className="primary-button loan-dashboard"
          onClick={onClick}
        >
          {i18next.t('LOAN_APPROVED.VIEW_DASHBOARD')}
        </button>
      </div>
    </div>
  );
};

LoanApproved.propTypes = {
  onClick: PropTypes.func.isRequired,
  application: PropTypes.instanceOf(Object).isRequired,
};
LoanApproved.defaultProps = {};
