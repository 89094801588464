import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

export const SettingHeader = ({ data, currentNav, setCurrentNav }) => (
  <>
    <div className="setting-title">{i18next.t('SETTINGS.TITLE')}</div>
    <div className="setting-navbar">
      {data.map(({ title, value }) => (
        <div
          key={value}
          className={`nav-item ${currentNav === value ? 'active' : ''}`}
          onClick={() => setCurrentNav(value)}
        >
          {title}
        </div>
      ))}
    </div>
  </>
);

SettingHeader.propTypes = {
  currentNav: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  setCurrentNav: PropTypes.func.isRequired,
};
SettingHeader.defaultProps = {};
