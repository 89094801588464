import { useStoreActions, useStoreState } from 'easy-peasy';
import React from 'react';

import { CrossIcon, ErrorIcon, TickIcon } from '../../icons';

const MESSAGE_TYPE = {
  success: TickIcon,
  error: ErrorIcon,
};

export const MessageToast = () => {
  const toasts = useStoreState(({ toasts }) => toasts);
  const popToast = useStoreActions((actions) => actions.popToast);
  return (
    <div className="message-toast-portal-container">
      {toasts.map(({
        id, title, message, type = 'success',
      }) => {
        const Icon = MESSAGE_TYPE[type];
        return (
          <div key={id} className="message-toast-container">
            <Icon className="message-icon" />
            <div className="message-content">
              <div className={`message-title ${type}`}>{title}</div>
              <div className="message-description">{message}</div>
            </div>
            <CrossIcon className="cross-icon" onClick={() => popToast(id)} />
          </div>
        );
      })}
    </div>
  );
};

MessageToast.propTypes = {};
MessageToast.defaultProps = {};
