import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path opacity={0.01} stroke="#979797" d="M.5.5h23v23H.5z" />
      <path
        clipRule="evenodd"
        d="M10.823 5.566L3.691 17.474A1.684 1.684 0 005.13 20h14.265a1.684 1.684 0 001.44-2.526L13.703 5.566a1.684 1.684 0 00-2.88 0z"
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.263 9.895v3.368M12.263 16.632h.009"
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
