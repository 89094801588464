import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

export const UserMenu = ({ onLogout }) => (
  <div className="user-menu">
    <Link className="user-menu-link" to="/setting">{i18next.t('USER_MENU.SETTINGS')}</Link>
    <div className="user-menu-link" onClick={onLogout}>{i18next.t('USER_MENU.LOG_OUT')}</div>
  </div>
);

UserMenu.propTypes = {
  onLogout: PropTypes.func,
};
UserMenu.defaultProps = {
  onLogout: () => {},
};
