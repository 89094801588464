import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

import { HelpIcon } from '../../icons';
import { onlyNumbers, onlyString } from '../../utils/textInputValid';
import { FormInput } from '../inputs';
import { EquityBlock, OwnerEquityID } from './common';
import { ControlButton } from './ControlButton';

export const DirectorsForm = ({
  active, next, prev, data, updateState,
}) => {
  const {
    directorName,
    directorTitle,
    directorAddress,
    directorshareOwnership,
    directorpartnershipAllocation,
    directorllcMembership,
    directorotherInterest,
    directorId,
    directorIdType,
  } = data;

  const updateFormState = (payload) => updateState('directors', payload);

  return (
    <form
      method="post"
      onSubmit={(e) => { e.preventDefault(); next(); }}
      className={`form-content ownership-director-management-container ${active ? 'active' : ''}`}
    >
      <div className="form-title">{i18next.t('DIRECTORS.TITLE')}</div>
      <div className="form-text-title ownership-more-help">
        {i18next.t('DIRECTORS.BLOCK1.TEXT')}
      </div>
      <div className="form-light-description board-directors-text">
        {i18next.t('DIRECTORS.BLOCK1.HINT')}
      </div>
      <div className="form-section">
        <div className="section-block column-2">
          <FormInput
            label={i18next.t('DIRECTORS.NAME.LABEL')}
            placeholder={i18next.t('DIRECTORS.NAME.LABEL')}
            value={directorName}
            onChange={(e) => updateFormState({ directorName: onlyString(e.target.value, 50) })}
            required
          />
          <FormInput
            label={i18next.t('DIRECTORS.NAME_TITLE.LABEL')}
            placeholder={i18next.t('DIRECTORS.NAME_TITLE.LABEL')}
            value={directorTitle}
            onChange={(e) => updateFormState({ directorTitle: onlyString(e.target.value, 50) })}
            required
          />
        </div>
      </div>
      <div className="form-section">
        <div className="section-block">
          <FormInput
            label={i18next.t('DIRECTORS.ADDRESS.LABEL')}
            placeholder={i18next.t('DIRECTORS.ADDRESS.LABEL')}
            description={i18next.t('DIRECTORS.ADDRESS.HINT')}
            value={directorAddress}
            onChange={(e) => updateFormState({ directorAddress: onlyString(e.target.value, 100) })}
            required
          />
        </div>
      </div>
      <EquityBlock
        title={i18next.t('DIRECTORS.BLOCK2.TITLE')}
        updateFormState={updateFormState}
        shareOwnership={directorshareOwnership}
        partnershipAllocation={directorpartnershipAllocation}
        llcMembership={directorllcMembership}
        otherInterest={directorotherInterest}
        shareOwnershipKey="directorshareOwnership"
        partnershipAllocationKey="directorpartnershipAllocation"
        llcMembershipKey="directorllcMembership"
        otherInterestKey="directorotherInterest"
      />
      <div className="form-section owner-identification">
        <div className="section-title">
          {i18next.t('DIRECTORS.DIRECTOR_ID.TITLE')}
          <HelpIcon
            className="help-icon"
            data-for="help-tooltip"
            data-tip={i18next.t('DIRECTORS.DIRECTOR_ID.HINT')}
            data-iscapture="true"
            data-event="click focus"
            data-event-off="blur"
          />
        </div>
        <div className="section-block column-2">
          <FormInput
            label={i18next.t('DIRECTORS.DIRECTOR_ID.TITLE')}
            placeholder={i18next.t('DIRECTORS.DIRECTOR_ID.TITLE')}
            value={directorId}
            onChange={(e) => updateFormState({ directorId: onlyNumbers(e.target.value, 10) })}
            required
          />
        </div>
      </div>

      <OwnerEquityID
        name="director-id-type"
        updateFormState={updateFormState}
        ownerIdType={directorIdType}
        ownerIdTypeKey="directorIdType"
        ownerIdDocKey="directorIdDoc"
      />

      <ControlButton
        nextTitle={i18next.t('BUTTONS.NEXT')}
        prevTitle={i18next.t('BUTTONS.PREVIOUS')}
        onPrev={prev}
        buttonPropsNext={{ type: 'submit' }}
      />
    </form>
  );
};

DirectorsForm.propTypes = {
  next: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,
  updateState: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  active: PropTypes.bool.isRequired,
};
DirectorsForm.defaultProps = {};
