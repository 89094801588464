import React from 'react';
import ReactTooltip from 'react-tooltip';

import { Layout } from '../components';
import { Setting } from '../components/dashboard';

export const SettingPage = ({ history: { push } }) => (
  <Layout>
    <Setting push={push} />
    <ReactTooltip id="upload-tooltip" place="bottom" effect="solid" clickable />
  </Layout>
);
SettingPage.propTypes = {};
SettingPage.defaultProps = {};
