import PropTypes from 'prop-types';
import React from 'react';

export const ErrorBlock = ({ error, className }) => (
  <div className={`error-block ${className}`}>
    {error}
  </div>
);

ErrorBlock.propTypes = {
  error: PropTypes.string.isRequired,
  className: PropTypes.string,
};
ErrorBlock.defaultProps = {
  className: '',
};
