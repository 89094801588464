import PropTypes from 'prop-types';
import React from 'react';

export const FormInput = ({
  label, description, type, ...props
}) => (
  <div className="form-input-container">
    {label && <div className="form-input-label">{label}</div>}
    <input type={!type ? 'text' : type} className="form-input" {...props} />
    {description && <div className="form-input-description">{description}</div>}
  </div>
);

FormInput.propTypes = {
  description: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
};
FormInput.defaultProps = {
  description: '',
  label: '',
  placeholder: '',
  type: '',
};
