import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

import { BackArrowIcon } from '../../../../../icons';

export const InstallmentDeduction = ({
  approved, monthlyInstallmentDate, mid, setMid,
}) => (
  <div className="installment-deduct">
    <div className="detail-title deduct-title">{i18next.t('APPLICATION_DETAIL_PAGE.DEDUCTION_DATE')}</div>
    <div className="deduct-select">
      {approved ? <span>{monthlyInstallmentDate}</span> : (
        <>
          <select
            value={mid}
            onChange={({ target: { value } }) => setMid(Number(value))}
          >
            {[...Array(28).fill()
              .map((_, id) => id + 1)]
              .map((date) => <option key={date}>{date}</option>)}
          </select>
          <BackArrowIcon />
        </>
      )}
    </div>
  </div>
);

InstallmentDeduction.propTypes = {
  approved: PropTypes.bool.isRequired,
  mid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  monthlyInstallmentDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setMid: PropTypes.func.isRequired,
};
InstallmentDeduction.defaultProps = {};
