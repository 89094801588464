export const API_ROUTE = {
  ME: '/me',
  USER_SIGNUP: '/signup',
  USER_SIGNIN: '/signin',
  USER_UPDATE: '/user/update',
  EMAIL_VERIFY: '/resend_verification',

  BOOSTER_UPLOAD_SEND: '/booster',

  FORM_CREATE: '/form/create',
  FORM_FETCH: '/form/fetch',
  FORM_UPDATE: '/form/update',
  FORM_UPLOAD: '/form/upload',
  FORM_FETCH_ALL: '/form/fetchAll', // Admin Route

  NOTIFICATIONS_FETCH: '/notifications',

  PAYMENT_LIST_ALL: '/payment/getall', // Admin Route
  GET_USER_PAYMENTS: '/stripe/payments',

  ADD_CREDIT_CARD: '/stripe/addcard',
  GET_CREDIT_CARDS: '/stripe/cards',
  REMOVE_CREDIT_CARDS: '/stripe/removecard',
  MAKE_PAYMENT: '/stripe/pay',
};
