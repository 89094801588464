import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

export const LoanRejected = ({ email }) => (
  <div className="loan-rejected-container">
    <div className="loan-rejected">
      <div className="rejected-header">{i18next.t('LOAN_REJECTED.HEADING')}</div>
      <div className="rejected-text">{i18next.t('LOAN_REJECTED.SUB_HEADING')}</div>
      <div className="rejected-contact">{i18next.t('LOAN_REJECTED.DESCRIPTION')}</div>
      <a className="rejected-contact" href={`mailto:${email}`}>{email}</a>
    </div>
  </div>
);

LoanRejected.propTypes = {
  email: PropTypes.string,
};
LoanRejected.defaultProps = {
  email: 'hello@meritloan.com',
};
