export const onlyNumbers = (string, limit) => {
  const finalString = `${string}`.replace(/[^0-9]/g, '').replace(/^0+/, '');
  if (limit) {
    return finalString.slice(0, limit);
  }
  return finalString;
};

export const onlyNumberRange = (string, max) => {
  const finalString = `${string}`.replace(/[^0-9]/g, '').replace(/^0+/, '');
  if (Number(finalString) >= Number(max)) { // reducing unit
    return max;
  }
  return finalString;
};

export const onlyString = (string, max) => {
  const finalString = `${string}`.slice(0, max);
  return finalString.trimStart();
};

export const onlyAlpha = (string, limit) => {
  const finalString = `${string}`.replace(/[^A-Za-z ]/g, '').trimStart();
  if (limit) {
    return finalString.slice(0, limit);
  }
  return finalString;
};
