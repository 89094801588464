import PropTypes from 'prop-types';
import React from 'react';

export const BusinessDetails = ({ data }) => (
  <div className="application-details-container column-three-details">
    {data.map(({ title, value, Icon }) => (
      <div key={title} className="detail-block">
        <div className="text-section">
          <div className="detail-title">{title}</div>
          <div className="detail-desc" title={value}>{value || '-'}</div>
        </div>
        {Icon && <Icon className="download-icon" />}
      </div>
    ))}
  </div>
);

BusinessDetails.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};
BusinessDetails.defaultProps = {};
