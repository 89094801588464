import React from 'react';

import { LoadingIcon } from '../../icons';

export const CoverLoader = () => (
  <div className="cover-loader" onClick={(e) => e.stopPropagation()}>
    <LoadingIcon />
  </div>
);

CoverLoader.propTypes = {};
CoverLoader.defaultProps = {};
