import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

import { GOVERNING_DOCS_DATA } from '../../../data';
import { DocumentUpload } from '../common/DocumentUpload';

export const GoverningDocument = ({ updateFormState }) => (
  <div className="form-section governing-document-section">
    <div className="section-title governing-title">
      {i18next.t('BUSINESS_PROFILE.GOVERNING_DOCS.TITLE')}
    </div>
    <div className="section-sub-title">
      {i18next.t('BUSINESS_PROFILE.GOVERNING_DOCS.SUBTITLE')}
    </div>
    <div className="section-block">
      {GOVERNING_DOCS_DATA().map(({ title, subTitle, key }) => (
        <DocumentUpload
          key={title}
          className="governing-documents"
          title={title}
          subTitle={subTitle}
          onUpload={(url) => updateFormState({ [key]: url })}
          description={i18next.t('BUSINESS_PROFILE.GOVERNING_DOCS.TEXT1')}
          required
        />
      ))}
    </div>
  </div>
);

GoverningDocument.propTypes = {
  updateFormState: PropTypes.func.isRequired,
};
GoverningDocument.defaultProps = {};
