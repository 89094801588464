import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

export const StatusButton = ({ status }) => (
  <div className="button-section">
    {status === 'waiting' && (
      <>
        <button type="submit" className="decline" name="declined">
          {i18next.t('ADMIN_STATUS_BUTTON.DECLINE')}
        </button>
        <button type="submit" className="approve" name="approved">
          {i18next.t('ADMIN_STATUS_BUTTON.APPROVE')}
        </button>
      </>
    )}
    {status === 'approved' && (<button type="button" className="approve approved">{i18next.t('ADMIN_STATUS_BUTTON.APPROVED')}</button>)}
    {status === 'declined' && (<button type="button" className="approve declined">{i18next.t('ADMIN_STATUS_BUTTON.DECLINED')}</button>)}
  </div>
);

StatusButton.propTypes = {
  status: PropTypes.string.isRequired,
};
StatusButton.defaultProps = {};
