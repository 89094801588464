import PropTypes from 'prop-types';
import * as React from 'react';

function SvgComponent({ percent, ...props }) {
  const radius = 63;
  const circumference = radius * 2 * Math.PI;
  const strokeDasharray = `${circumference} ${circumference}`;
  const strokeDashoffset = circumference - ((percent / 100) * circumference);
  return (
    <svg width={146} height={146} viewBox="0 0 146 146" fill="none" {...props}>
      <circle
        r={radius}
        cx={73}
        cy={73}
        className="progress-ring__circle"
        stroke="#00A688"
        strokeWidth={20}
        fill="transparent"
        style={{ strokeDasharray, strokeDashoffset, transition: '0.35s stroke-dashoffset' }}
        transform="rotate(270 73 73)"
      />
      <circle cx={73} cy={73} r={63} fill="#fff" />
      <text
        x="50%"
        y="50%"
        fill="#323232"
        dominantBaseline="middle"
        textAnchor="middle"
        className="circle-percent-text"
      >
        {`${percent}%`}
      </text>
    </svg>
  );
}

SvgComponent.propTypes = {
  percent: PropTypes.number.isRequired,
};

export default SvgComponent;
