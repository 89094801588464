import i18next from 'i18next';
import moment from 'moment';
import Proptypes from 'prop-types';
import React, { useRef, useState } from 'react';

import { numberToAmount, onTimePaymentAll } from '../../../utils';
import { CommonTable } from '../../common';
import { AdminLayout } from '../AdminLayout';
import { PaymentListItem } from './PaymentListItem';

const ADMIN_PAYMENT_NAV = () => [
  { title: i18next.t('ADMIN_PAYMENT_NAV.NAV1'), value: 'all_payments' },
  { title: i18next.t('ADMIN_PAYMENT_NAV.NAV2'), value: 'upcoming' },
  { title: i18next.t('ADMIN_PAYMENT_NAV.NAV3'), value: 'overdue' },
];

const PAYMENT_TABLE_HEADER = () => [
  i18next.t('ADMIN_PAYMENT_PAGE.PAYMENT_TABLE.COL1'),
  i18next.t('ADMIN_PAYMENT_PAGE.PAYMENT_TABLE.COL2'),
  i18next.t('ADMIN_PAYMENT_PAGE.PAYMENT_TABLE.COL3'),
  i18next.t('ADMIN_PAYMENT_PAGE.PAYMENT_TABLE.COL4'),
  i18next.t('ADMIN_PAYMENT_PAGE.PAYMENT_TABLE.COL5'),
];

export const AdminPaymentPage = ({ payments, applications }) => {
  const [subNav, setSubNav] = useState(ADMIN_PAYMENT_NAV()[0].value);
  const tableBodyRef = useRef();
  const processedPayments = payments
    .filter(({ paymentIdStripe }) => !paymentIdStripe)// Filters all unpaid payments
    .map((payment) => { // this finds user application and adds status key to the new object
      const { businessProfile: { legalName } } = applications
        .find((application) => application.user === payment.userId);
      const status = moment(payment.installmentDate).unix() > moment().unix() ? 'upcoming' : 'overdue';
      return { ...payment, legalName, status };
    })
    .sort((a, b) => { // This will sort the processed payments
      const dateA = Number(moment(a.installmentDate).unix());
      const dateB = Number(moment(b.installmentDate).unix());
      return dateA - dateB;
    });

  // Filters data according to navbar selection
  const filteredPaymentData = (subNav === ADMIN_PAYMENT_NAV()[0].value)
    ? processedPayments
    : processedPayments.filter(({ status }) => subNav.toLowerCase() === status);

  const paymentOnTimePercentages = onTimePaymentAll(payments);

  return (
    <AdminLayout
      title={i18next.t('ADMIN_PAYMENT_PAGE.HEADING')}
      subNavData={ADMIN_PAYMENT_NAV()}
      subNavOnClick={(nav) => {
        setSubNav(nav);
        tableBodyRef.current.scrollTo({ top: 0, behavior: 'smooth' });
      }}
      subNavActive={subNav}
    >
      <CommonTable headerList={PAYMENT_TABLE_HEADER()} bodyRef={tableBodyRef}>
        {filteredPaymentData.map(({
          _id, amount, legalName, installmentDate, status, userId,
        }) => (
          <PaymentListItem
            key={_id}
            title={legalName}
            day={moment(installmentDate).format('DD MMM YYYY')}
            amount={numberToAmount({ number: amount, limit: true })}
            percent={`${paymentOnTimePercentages[userId] || 0}%`}
            status={status}
          />
        ))}
      </CommonTable>
    </AdminLayout>
  );
};

AdminPaymentPage.propTypes = {
  payments: Proptypes.instanceOf(Object).isRequired,
  applications: Proptypes.instanceOf(Object).isRequired,
};
AdminPaymentPage.defaultProps = {};
