export const stripeStype = {
  base: {
    fontSize: '20px',
    color: '#000000bf',
    // fontFamily: 'Poppins',
    '::placeholder': {
      color: '#aab7c4',
    },
  },
  invalid: { color: '#FF4C4C' },
};
