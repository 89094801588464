import axios from 'axios';
import { useStoreActions, useStoreState } from 'easy-peasy';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';

import { API_ROUTE } from '../../../apiRoutes';
import { NotificationSetting } from './NotificationSetting';
import { PasswordSetting } from './PasswordSetting';
import { ProfileSetting } from './ProfileSetting';
import { SettingHeader } from './SettingHeader';

const SETTING_NAV_DATA = () => [
  { title: i18next.t('SETTINGS.NAV1'), value: 'profile' },
  { title: i18next.t('SETTINGS.NAV2'), value: 'password' },
  { title: i18next.t('SETTINGS.NAV3'), value: 'notifications' },
];

const downloadImage = (image, callback) => {
  axios.get(image, {
    responseType: 'blob',
  })
    .then(({ data }) => {
      const objectURL = URL.createObjectURL(data);
      callback(objectURL);
      return objectURL;
    })
    .catch((err) => {
      console.log('error in axios blob res:', err);
    });
};

export const Setting = () => {
  const user = useStoreState(({ auth }) => auth.user);
  const updateUserData = useStoreActions((actions) => actions.storeUserData);
  const pushToast = useStoreActions((actions) => actions.pushToast);
  const oldNotificationSetting = {
    productUpdates: user.productUpdates,
    requestStatus: user.requestStatus,
  };
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState('');
  const [image, setImage] = useState(user.profileUrl);
  const [blob, setBlob] = useState('');
  const [currentNav, setCurrentNav] = useState(SETTING_NAV_DATA()[0].value);
  const [notification, setNotification] = useState(
    Object.keys(oldNotificationSetting)
      .filter((key) => oldNotificationSetting[key] === true),
  );

  useEffect(() => {
    ReactTooltip.rebuild();
    downloadImage(image, setBlob);
  }, []);

  useEffect(() => {
    downloadImage(image, setBlob);
  }, [image]);

  const updateUser = (payload) => {
    axios.post(API_ROUTE.USER_UPDATE, payload)
      .then(({ data }) => {
        pushToast({ data: data.message });
        updateUserData({ user: data.user, token: data.token });
      })
      .catch((error) => {
        const { response, message } = error;
        if (response) {
          pushToast({ data: 'SETTING_NOT_UPDATED', type: 'error' });
        } else {
          pushToast({ data: message, type: 'error' });
        }
      });
    setPassword('');
  };
  return (
    <div className="setting-page-container">
      <div className="setting-page">
        <SettingHeader
          data={SETTING_NAV_DATA()}
          currentNav={currentNav}
          setCurrentNav={setCurrentNav}
        />
        {currentNav === SETTING_NAV_DATA()[0].value && (
          <ProfileSetting
            updateUser={() => updateUser({ name, email, profileUrl: image })}
            setImage={setImage}
            setName={setName}
            setEmail={setEmail}
            name={name}
            email={email}
            image={image}
            blob={blob}
          />
        )}
        {currentNav === SETTING_NAV_DATA()[1].value && (
          <PasswordSetting
            updateUser={() => updateUser({ password })}
            setPassword={setPassword}
            password={password}
          />
        )}
        {currentNav === SETTING_NAV_DATA()[2].value && (
          <NotificationSetting
            updateUser={() => updateUser({
              productUpdates: notification.includes('productUpdates'),
              requestStatus: notification.includes('requestStatus'),
            })}
            notification={notification}
            setNotification={setNotification}
          />
        )}
      </div>
    </div>
  );
};

Setting.propTypes = {};
Setting.defaultProps = {};
