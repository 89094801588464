import axios from 'axios';
import { useStoreActions } from 'easy-peasy';
import i18next from 'i18next';
import React from 'react';

import { API_ROUTE } from '../apiRoutes';

export const EmailConfirmBar = () => {
  const pushToast = useStoreActions((actions) => actions.pushToast);
  const confirmEmail = () => {
    axios.get(API_ROUTE.EMAIL_VERIFY)
      .then(({ data }) => {
        const { message } = data;
        pushToast({ data: message });
      })
      .catch((error) => {
        const { response, message } = error;
        if (response) {
          pushToast({ data: response.data.message, type: 'error' });
        } else {
          pushToast({ data: message, type: 'error' });
        }
      });
  };
  return (
    <div className="email-confirm-bar">
      {i18next.t('EMAIL_BAR.EMAIL_SENT')}
      <span onClick={confirmEmail}>{i18next.t('EMAIL_BAR.RESEND')}</span>
    </div>
  );
};

EmailConfirmBar.propTypes = {};
EmailConfirmBar.defaultProps = {};
