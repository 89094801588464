import PropTypes from 'prop-types';
import React from 'react';

export const BoosterCard = ({ title, description, onClick }) => (
  <div className="booster-card" onClick={onClick}>
    <div className="booster-card-title">{title}</div>
    <div className="booster-card-content">{description}</div>
  </div>
);

BoosterCard.propTypes = {
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
BoosterCard.defaultProps = {};
