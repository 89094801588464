import React from 'react';

import { FormInput } from '../../inputs';

export const TextInputGroup = ({ data }) => (
  data.map(({ title, className, rowData }) => (
    <div key={title} className="form-section">
      <div className={`section-block ${className}`}>
        {rowData.map(({
          label, value, onChange, required, description, inputProps,
        }) => (
          <FormInput
            key={label}
            label={label}
            placeholder={label}
            value={value}
            onChange={({ target: { value } }) => onChange(value)}
            description={description}
            required={required}
            {...inputProps}
          />
        ))}
      </div>
    </div>
  )));

TextInputGroup.propTypes = {};
TextInputGroup.defaultProps = {};
