import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { RadioCircle, RadioTick } from '../../icons';

export const RadioInput = ({
  selected, title, value, className, onChange, inputProps, required, name,
}) => (
  <div className={`radio-container ${className}`} onClick={() => onChange(value)}>
    {selected === value ? <RadioTick dark /> : <RadioCircle dark />}
    <div className="title">{title}</div>
    {!isEmpty(inputProps) && (
    <input
      className="radio-text-input"
      type="text"
      value={inputProps.value}
      onChange={(e) => inputProps.onChange(e.target.value)}
      {...inputProps}
    />
    )}
    <input
      className="validation-checkbox"
      name={name}
      type="radio"
      onChange={() => {}}
      required={required}
      checked={selected}
    />
  </div>
);

RadioInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  inputProps: PropTypes.instanceOf(Object),
  required: PropTypes.bool,
  name: PropTypes.string,
};
RadioInput.defaultProps = {
  className: '',
  inputProps: {},
  required: true,
  name: '',
};
