import axios from 'axios';
import { useStoreActions, useStoreRehydrated, useStoreState } from 'easy-peasy';
import i18next from 'i18next';
import jwtDecode from 'jwt-decode';
import React, { useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

import { API_ROUTE } from './apiRoutes';
import { MessageToast } from './components/common';
import { BACKEND_BASE_URL } from './environment';
import { en, ru } from './locales';
import { ROUTES } from './routes';

axios.defaults.baseURL = BACKEND_BASE_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';

export const AppComponent = ({ history: { push } }) => {
  const isRehydrated = useStoreRehydrated();
  const { token } = useStoreState(({ auth }) => auth);
  const logout = useStoreActions((actions) => actions.logOut);
  const pushToast = useStoreActions((actions) => actions.pushToast);
  const storeUserData = useStoreActions((actions) => actions.storeUserData);
  const updateUserApplication = useStoreActions((actions) => actions.updateUserApplication);

  axios.defaults.headers.common.Authorization = token;

  const getData = () => {
    try {
      return jwtDecode(token);
    } catch (error) {
      return {};
    }
  };
  const userData = getData();
  useEffect(() => {
    if (token) {
      axios.get(API_ROUTE.ME)
        .then(({ data }) => {
          storeUserData({ user: data.user });
          if (data.application) {
            updateUserApplication(data.application);
          } else {
            updateUserApplication(null);
          }
        })
        .catch((error) => {
          const { response, message } = error;
          if (response) {
            pushToast({ data: 'USER_LOGGED_OUT', type: 'error' });
          } else {
            pushToast({ data: message, type: 'error' });
          }
          logout();
          push('/login');
        });
    }
  }, [isRehydrated]);
  i18next
    .init({
      resources: { en, ru },
      lng: 'en',
      fallbackLng: 'en',
      interpolation: { escapeValue: false },
    });
  return isRehydrated
    ? (
      <div className="merit-app">
        <MessageToast />
        <Switch>
          {ROUTES(userData).map(({
            path, render, name, exact = false,
          }) => (
            <Route key={path} name={name} path={path} render={render} exact={exact} />
          ))}
        </Switch>
      </div>
    )
    : <div>Loading...</div>;
};

AppComponent.propTypes = {};
AppComponent.defaultProps = {};

export default withRouter(AppComponent);
