import moment from 'moment';

export const onTimePayment = (payments) => {
  const onTimeData = payments.map(({
    paidAt, installmentDate,
  }) => moment(paidAt).unix() < moment(installmentDate).unix());
  const onTimeNumber = onTimeData.filter((node) => node);
  return ((onTimeNumber.length / onTimeData.length) * 100);
};

export const onTimePaymentAll = (paymentsAll) => {
  const paymentGroups = paymentsAll.filter(({ paymentIdStripe }) => paymentIdStripe);
  const paymentOnTimeObject = {};
  const allUsersId = [...new Set(paymentGroups.map(({ userId }) => userId))];
  allUsersId.forEach((uId) => {
    const userPayments = paymentGroups.filter(({ userId }) => userId === uId);
    const percentage = onTimePayment(userPayments);
    paymentOnTimeObject[uId] = percentage;
  });
  return paymentOnTimeObject;
};
