import axios from 'axios';
import { useStoreActions, useStoreState } from 'easy-peasy';
import i18next from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import { API_ROUTE } from '../apiRoutes';
import {
  CrossIcon,
  Logo, NotificationBell, UserIcon,
} from '../icons';
import { NotificationDialog, UserMenu } from './common';

const navLinks = () => [
  { title: i18next.t('NAV_BAR.BUILDER_PAYMENTS'), link: 'home' },
  { title: i18next.t('NAV_BAR.BOOSTER'), link: 'booster' },
  { title: i18next.t('NAV_BAR.EXTERNAL_PAYMENTS'), link: 'external' },
];

const NavbarComponent = ({ match: { params: { page }, path }, history: { goBack } }) => {
  const notificationContainer = useRef();
  const menuContainer = useRef();
  const [showMenu, setShowMenu] = useState(false);
  const [notificationMenu, setNotificationMenu] = useState(false);
  const logout = useStoreActions((actions) => actions.logOut);
  const addNotifications = useStoreActions((actions) => actions.addNotifications);
  const notifications = useStoreState(({ notifications }) => notifications);

  const fetchNotifications = () => axios.get(API_ROUTE.NOTIFICATIONS_FETCH)
    .then(({ data }) => {
      addNotifications(data.data);
    })
    .catch((err) => {
      console.log('notification failed', err);
    });

  useEffect(() => {
    if (notificationMenu) {
      fetchNotifications();
    }
  }, [notificationMenu]);
  useEffect(() => {
    fetchNotifications();
  }, []);

  const handleClickOutsideNotification = (event) => {
    if (notificationContainer.current && !notificationContainer.current.contains(event.target)) {
      setNotificationMenu(false);
    }
  };

  const handleClickOutsideMenu = (event) => {
    if (menuContainer.current && !menuContainer.current.contains(event.target)) {
      setShowMenu(false);
    }
  };

  const handleOnScrollDialog = () => {
    if (showMenu || notificationMenu) {
      setShowMenu(false);
      setNotificationMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideNotification);
    document.addEventListener('mousedown', handleClickOutsideMenu);
    document.addEventListener('scroll', handleOnScrollDialog);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideNotification);
      document.removeEventListener('mousedown', handleClickOutsideMenu);
      document.removeEventListener('scroll', handleOnScrollDialog);
    };
  });

  return (
    <div className="merit-navbar">
      <div><Logo /></div>
      {page && (
      <div className="merit-links">
        {navLinks().map(({ title, link }) => (
          <NavLink
            key={title}
            className="merit-nav-link "
            activeClassName="active"
            to={`/dashboard/${link}`}
          >
            {title}
          </NavLink>
        ))}
      </div>
      )}
      <div className="merit-navbar-icons">
        {path === '/setting'
          ? <CrossIcon className="cross-back" onClick={goBack} /> : (
            <>
              <div ref={notificationContainer} className="navbar-notification-container">
                <NotificationBell className="navbar-icon" onClick={() => setNotificationMenu(!notificationMenu)} />
                {notificationMenu && <NotificationDialog notifications={notifications} />}
              </div>
              <div ref={menuContainer} className="merit-navbar-link navbar-icon">
                <UserIcon onClick={() => setShowMenu(!showMenu)} />
                {showMenu && <UserMenu onLogout={logout} />}
              </div>
            </>
          )}
      </div>
    </div>
  );
};
NavbarComponent.propTypes = {};
NavbarComponent.defaultProps = {};

export const Navbar = withRouter(NavbarComponent);
