import { useStoreState } from 'easy-peasy';
import React from 'react';

import { EmailConfirmBar } from './EmailConfirmBar';
import { Navbar } from './Navbar';

export const Layout = ({ children }) => {
  const { user } = useStoreState(({ auth }) => auth);
  const { isVerified } = user || {};
  return (
    <>
      <Navbar />
      {!isVerified && <EmailConfirmBar />}
      {children}
    </>
  );
};

Layout.propTypes = {};
Layout.defaultProps = {};
