import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { numberToAmount, onlyNumbers } from '../../utils';

export const RequestedAmountInput = ({ onChange, value, ...props }) => {
  const [editMode, setEditMode] = useState(false);
  const inputContainer = useRef();
  const inputRef = useRef();
  const handleClickOutsideInput = (event) => {
    if (inputContainer.current && !inputContainer.current.contains(event.target)) {
      setEditMode(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideInput);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideInput);
    };
  });

  useEffect(() => {
    if (editMode) {
      inputRef.current.focus();
    }
  }, [editMode]);

  const setEdit = () => {
    setEditMode(!editMode);
  };

  return (
    <div ref={inputContainer} className="requested-amount-input">
      <input
        ref={inputRef}
        className={`amount-input ${!editMode ? 'disbaled' : ''}`}
        type="text"
        title={i18next.t('APPLICATION_DETAIL_PAGE.MESSAGE.AMOUNT')}
        onChange={({ target: { value } }) => onChange(onlyNumbers(value, 6))}
        value={editMode ? value : numberToAmount({ number: value, currency: '' })}
        {...(Number(value) === 0 ? { pattern: '^[1-9]\\d*$' } : {})}
        {...props}
      />
      <div className="edit" onClick={setEdit}>Edit</div>
    </div>
  );
};

RequestedAmountInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
RequestedAmountInput.defaultProps = {};
