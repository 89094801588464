import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

import {
  BUSINESS_ENTITY_DATA, GOOD_STANDING_DATA, TAX_CLASSIFICATION_DATA,
} from '../../../data';
import { onlyNumbers, onlyString } from '../../../utils';
import { FormInput, RadioInput, UploadInput } from '../../inputs';
import { TextInputGroup } from '../common';
import { ControlButton } from '../ControlButton';
import { BUSSINESS_NAME_DATA } from './constants';
import { GoverningDocument } from './GoverningDocument';

export const BussinessProfileForm = ({
  active, next, prev, data, updateState,
}) => {
  const {
    orgState,
    goodStanding,
    businessEntity,
    businessEntityOther,
    taxClassification,
    taxClassificationOther,
    businessPrimaryAdd,
    businessRegisteredAdd,
    phone,
    fax,
    email,
    annualRevenueLtm,
    annualRevenuePriorYear,
    annualProfitLtm,
    annualProfitPriorYear,
    annualRevenueLtmOther,
    annualProfitLtmOther,
    primaryBank,
    outstandingDebt,
    availableCash,
  } = data;

  const updateFormState = (payload) => updateState('businessProfile', payload);

  return (
    <form
      method="post"
      onSubmit={(e) => { e.preventDefault(); next(); }}
      className={`form-content bussiness-profile-form ${active ? 'active' : ''}`}
    >
      <div className="form-title">{ i18next.t('BUSINESS_PROFILE.TITLE') }</div>

      <TextInputGroup data={BUSSINESS_NAME_DATA({ ...data, updateFormState })} />

      <div className="form-section">
        <div className="section-block state-standing-section">
          <div className="section-content">
            <div className="state-organization-container">
              <FormInput
                label={i18next.t('BUSINESS_PROFILE.STATE.LABEL')}
                placeholder={i18next.t('BUSINESS_PROFILE.STATE.LABEL')}
                description={i18next.t('BUSINESS_PROFILE.STATE.HINT')}
                value={orgState}
                onChange={({ target: { value } }) => updateFormState({
                  orgState: onlyString(value, 50),
                })}
                required
              />
            </div>
            <div className="state-organization-upload">
              <div className="form-description-text">
                {i18next.t('BUSINESS_PROFILE.STATE.TEXT1')}
              </div>
              <div className="upload-file">
                <UploadInput onUpload={(url) => updateFormState({ orgStateDoc: url })} required />
              </div>
            </div>
          </div>
          <div className="section-content">
            <div className="good-stand-content">
              <div className="form-text-title good-stand-head">
                {i18next.t('BUSINESS_PROFILE.GOOD_STANDING.TITLE')}
              </div>
              <div className="form-description-text good-stand-text">
                {i18next.t('BUSINESS_PROFILE.GOOD_STANDING.TEXT1')}
              </div>
              <div className="good-stand-radio">
                {GOOD_STANDING_DATA().map(({ title, value }) => (
                  <RadioInput
                    key={title}
                    name="good-standing"
                    title={title}
                    value={value}
                    selected={goodStanding}
                    onChange={(value) => updateFormState({ goodStanding: value })}
                    required
                  />
                ))}
              </div>
            </div>
            <div className="good-stand-upload">
              <div className="form-description-text good-stand-upload-text">
                {i18next.t('BUSINESS_PROFILE.GOOD_STANDING.TEXT2')}
              </div>
              <div className="good-stand-upload-file">
                <UploadInput
                  onUpload={(url) => updateFormState({ goodStandingDoc: url })}
                  required={goodStanding === 'yes'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-section business-entity-section">
        <div className="section-title business-title">
          { i18next.t('BUSINESS_PROFILE.BUSINESS_ENTITY.TITLE') }
        </div>
        <div className="section-sub-title">
          { i18next.t('BUSINESS_PROFILE.BUSINESS_ENTITY.SUBTITLE') }
        </div>
        <div className="section-content">
          {BUSINESS_ENTITY_DATA().map(({ title, value }) => (
            <RadioInput
              key={title}
              name="bussiness-entity"
              title={title}
              value={value}
              selected={businessEntity}
              onChange={(value) => updateFormState({ businessEntity: value, businessEntityOther: '' })}
              required
            />
          ))}
          <RadioInput
            name="bussiness-entity"
            title="Other:"
            value="Other"
            selected={businessEntity}
            onChange={(value) => updateFormState({ businessEntity: value })}
            inputProps={{
              value: businessEntityOther,
              onChange: ({ target: { value } }) => updateFormState({
                businessEntityOther: onlyString(value, 30),
              }),
              required: businessEntity === 'Other',
            }}
            required
          />
        </div>
      </div>

      <GoverningDocument updateFormState={updateFormState} />

      <div className="form-section tax-reporting-section">
        <div className="section-title">
          {i18next.t('BUSINESS_PROFILE.TAX_CLASSIFICATION.TITLE')}
        </div>
        <div className="section-sub-title">
          {i18next.t('BUSINESS_PROFILE.TAX_CLASSIFICATION.SUBTITLE')}
        </div>
        <div className="section-content">
          {TAX_CLASSIFICATION_DATA().map(({ title, value }) => (
            <RadioInput
              key={title}
              name="tax-classification"
              title={title}
              value={value}
              selected={taxClassification}
              onChange={(value) => updateFormState({ taxClassification: value, taxClassificationOther: '' })}
              required
            />
          ))}
          <RadioInput
            name="tax-classification"
            title="Other:"
            value="Other"
            selected={taxClassification}
            onChange={(value) => updateFormState({ taxClassification: value })}
            inputProps={{
              value: taxClassificationOther,
              onChange: ({ target: { value } }) => updateFormState({
                taxClassificationOther: onlyString(value, 50),
              }),
              required: taxClassification === 'Other',
            }}
            required
          />
        </div>
      </div>
      <div className="form-section">
        <div className="section-block">
          <FormInput
            label={i18next.t('BUSINESS_PROFILE.ADDRESS1.LABEL')}
            placeholder={i18next.t('BUSINESS_PROFILE.ADDRESS1.LABEL')}
            description={i18next.t('BUSINESS_PROFILE.ADDRESS1.HINT')}
            value={businessPrimaryAdd}
            onChange={({ target: { value } }) => updateFormState({
              businessPrimaryAdd: onlyString(value, 100),
            })}
            required
          />
        </div>
      </div>
      <div className="form-section">
        <div className="section-block">
          <FormInput
            label={i18next.t('BUSINESS_PROFILE.ADDRESS2.LABEL')}
            placeholder={i18next.t('BUSINESS_PROFILE.ADDRESS2.LABEL')}
            description={i18next.t('BUSINESS_PROFILE.ADDRESS2.HINT')}
            value={businessRegisteredAdd}
            onChange={({ target: { value } }) => updateFormState({
              businessRegisteredAdd: onlyString(value, 100),
            })}
            required
          />
        </div>
      </div>
      <div className="form-section">
        <div className="section-block column-3">
          <FormInput
            label={i18next.t('BUSINESS_PROFILE.PHONE')}
            placeholder={i18next.t('BUSINESS_PROFILE.PHONE')}
            value={phone}
            minLength="7"
            onChange={({ target: { value } }) => updateFormState({ phone: onlyNumbers(value, 13) })}
            required
          />
          <FormInput
            label={i18next.t('BUSINESS_PROFILE.FAX')}
            placeholder={i18next.t('BUSINESS_PROFILE.FAX')}
            value={fax}
            minLength="7"
            onChange={({ target: { value } }) => updateFormState({ fax: onlyNumbers(value, 13) })}
            required
          />
          <FormInput
            type="email"
            label={i18next.t('BUSINESS_PROFILE.EMAIL')}
            placeholder={i18next.t('BUSINESS_PROFILE.EMAIL')}
            value={email}
            onChange={({ target: { value } }) => updateFormState({ email: value })}
            required
          />
        </div>
      </div>
      <div className="form-section revenue-profit-section">
        <div className="section-block column-2">
          {[{
            name: 'annual-revenue',
            title: i18next.t('BUSINESS_PROFILE.REVENUE.LABEL'),
            desc: i18next.t('BUSINESS_PROFILE.REVENUE.HINT'),
            selected: annualRevenuePriorYear,
            onChange: ({ target: { value } }) => updateFormState({
              annualRevenuePriorYear: onlyNumbers(value, 20),
            }),
            radioChange: (value) => updateFormState({ annualRevenueLtm: value, annualRevenueLtmOther: '' }),
            radioSelected: annualRevenueLtm,
            radioSelectedOther: annualRevenueLtmOther,
            radioChangeOther: ({ target: { value } }) => updateFormState({
              annualRevenueLtmOther: onlyString(value, 50),
            }),
          },
          {
            name: 'annual-profit',
            title: i18next.t('BUSINESS_PROFILE.PROFIT.LABEL'),
            desc: i18next.t('BUSINESS_PROFILE.PROFIT.HINT'),
            selected: annualProfitPriorYear,
            onChange: ({ target: { value } }) => updateFormState({
              annualProfitPriorYear: onlyNumbers(value, 20),
            }),
            radioChange: (value) => updateFormState({ annualProfitLtm: value, annualProfitLtmOther: '' }),
            radioSelected: annualProfitLtm,
            radioSelectedOther: annualProfitLtmOther,
            radioChangeOther: ({ target: { value } }) => updateFormState({
              annualProfitLtmOther: onlyString(value, 50),
            }),
          }].map(({
            name, title, desc, selected, onChange, radioSelected, radioChange,
            radioSelectedOther, radioChangeOther,
          }) => (
            <div key={title} className="annual-content">
              <FormInput
                label={title}
                placeholder={title}
                description={desc}
                value={selected}
                onChange={onChange}
                required
              />
              <div className="annual-radio">
                <RadioInput
                  name={name}
                  title={i18next.t('BUSINESS_PROFILE.REVENUE.OPTION1')}
                  value="Trust"
                  selected={radioSelected}
                  onChange={radioChange}
                  required
                />
                <RadioInput
                  name={name}
                  title={i18next.t('BUSINESS_PROFILE.REVENUE.OPTION2')}
                  value="Other"
                  selected={radioSelected}
                  onChange={radioChange}
                  inputProps={{
                    value: radioSelectedOther,
                    onChange: radioChangeOther,
                    required: radioSelected === 'Other',
                  }}
                  required
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="form-section statement-section">
        <div className="section-content">
          <div className="form-text-title statement-heading">
            {i18next.t('BUSINESS_PROFILE.FINANCIAL.TITLE')}
          </div>
          <div className="form-description-text statement-sub-heading">
            {i18next.t('BUSINESS_PROFILE.FINANCIAL.SUBTITLE')}
          </div>
          <div className="statement-upload-group">
            <div className="statement-upload">
              <div className="statement-text">
                {i18next.t('BUSINESS_PROFILE.FINANCIAL.TEXT1')}
              </div>
              <UploadInput
                onUpload={(url) => updateFormState({ financialStatementDoc: url })}
                required
              />
            </div>
            <div className="statement-upload">
              <div className="statement-text">
                {i18next.t('BUSINESS_PROFILE.FINANCIAL.TEXT2')}
              </div>
              <UploadInput
                onUpload={(url) => updateFormState({ financialStatementOptionalDoc: url })}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="form-section">
        <div className="section-block column-3">
          <FormInput
            label={i18next.t('BUSINESS_PROFILE.BANK.LABEL')}
            placeholder={i18next.t('BUSINESS_PROFILE.BANK.LABEL')}
            description={i18next.t('BUSINESS_PROFILE.BANK.HINT')}
            value={primaryBank}
            onChange={({ target: { value } }) => updateFormState({
              primaryBank: onlyString(value, 50),
            })}
            required
          />
          <FormInput
            label={i18next.t('BUSINESS_PROFILE.DEBT.LABEL')}
            placeholder={i18next.t('BUSINESS_PROFILE.DEBT.LABEL')}
            description={i18next.t('BUSINESS_PROFILE.DEBT.HINT')}
            value={outstandingDebt}
            minLength="4"
            onChange={({ target: { value } }) => updateFormState({
              outstandingDebt: onlyNumbers(value, 20),
            })}
            required
          />
          <FormInput
            label={i18next.t('BUSINESS_PROFILE.AVAILABLE.LABEL')}
            placeholder={i18next.t('BUSINESS_PROFILE.AVAILABLE.LABEL')}
            description={i18next.t('BUSINESS_PROFILE.AVAILABLE.HINT')}
            value={availableCash}
            minLength="4"
            onChange={({ target: { value } }) => updateFormState({
              availableCash: onlyNumbers(value, 20),
            })}
            required
          />
        </div>
      </div>
      <div className="form-section statement-section">
        <div className="section-content">
          <div className="statement-heading form-text-title">
            {i18next.t('BUSINESS_PROFILE.CURRENT_BANK.TITLE')}
          </div>
          <div className="form-description-text statement-sub-heading">
            {i18next.t('BUSINESS_PROFILE.CURRENT_BANK.SUBTITLE')}
          </div>
          <UploadInput onUpload={(url) => updateFormState({ bankStatement: url })} required />
        </div>
      </div>
      <div className="business-profile-note">
        {i18next.t('BUSINESS_PROFILE.HELP_TEXT')}
      </div>
      <ControlButton
        nextTitle={i18next.t('BUTTONS.NEXT')}
        prevTitle={i18next.t('BUTTONS.PREVIOUS')}
        onPrev={prev}
        buttonPropsNext={{ type: 'submit' }}
      />
    </form>
  );
};

BussinessProfileForm.propTypes = {
  next: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,
  updateState: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  active: PropTypes.bool.isRequired,
};
BussinessProfileForm.defaultProps = {};
