import i18next from 'i18next';

export const TERMS_DATA = () => ([
  // {
  //   title: i18next.t('CERTIFICATIONS.SECTION1.OPTION1'),
  //   value: 'merit_nda',
  // },
  {
    title: i18next.t('CERTIFICATIONS.SECTION1.OPTION2'),
    value: 'privacy_ploicy',
    url: 'https://www.google.com',
  },
  {
    title: i18next.t('CERTIFICATIONS.SECTION1.OPTION3'),
    value: 'merit_account_management',
    url: 'https://www.google.com',
  },
]);

export const FINAL_RADIO_DATA = () => ([
  {
    title: i18next.t('CERTIFICATIONS.SECTION_INPUT.OPTION1'), value: 'yes',
  },
  {
    title: i18next.t('CERTIFICATIONS.SECTION_INPUT.OPTION2'), value: 'no',
  },
]);
