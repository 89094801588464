import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

import { CheckBox } from '../../inputs';

const notificationData = () => [
  {
    title: i18next.t('SETTINGS.NOTIFICATION_ITEM1'),
    value: 'requestStatus',
  },
  {
    title: i18next.t('SETTINGS.NOTIFICATION_ITEM2'),
    value: 'productUpdates',
  },
];

export const NotificationSetting = ({ updateUser, notification, setNotification }) => (
  <div className="profile-notification">
    <div className="setting-form-group">
      <div className="setting-input-title">{i18next.t('SETTINGS.EMAIL_NOTIFICATIONS')}</div>
      <div className="setting-checkboxes">
        {notificationData().map(({ title, value }) => (
          <CheckBox
            key={value}
            title={title}
            value={value}
            selected={notification}
            onChange={setNotification}
          />
        ))}
      </div>
    </div>
    <button type="button" className="primary-button save-button" onClick={updateUser}>
      {i18next.t('SETTINGS.NOTIFICATIONS_SAVE_BTN')}
    </button>
  </div>
);

NotificationSetting.propTypes = {
  notification: PropTypes.instanceOf(Array).isRequired,
  setNotification: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
};
NotificationSetting.defaultProps = {};
