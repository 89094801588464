import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={91} height={91} viewBox="0 0 91 91" fill="none" {...props}>
      <circle
        cx={45.5}
        cy={45.5}
        r={43.5}
        stroke="#fff"
        strokeWidth={4}
        strokeDasharray={80}
      />
    </svg>
  );
}

export default SvgComponent;
