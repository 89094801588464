import PropTypes from 'prop-types';
import React from 'react';

import { CompanyLogo, StatusNode } from '../../common';

export const PaymentListItem = ({
  title, amount, day, percent, status,
}) => (
  <div key={title + amount} className="common-list-item">
    <div className="common-list-column">
      <CompanyLogo title={title} />
    </div>
    <div className="common-list-column table-text-dark light">{day}</div>
    <div className="common-list-column table-text-dark light">{amount}</div>
    <div className="common-list-column table-text-dark">{percent}</div>
    <div className="common-list-column">
      <StatusNode mode={status} />
    </div>
  </div>
);

PaymentListItem.propTypes = {
  amount: PropTypes.string.isRequired,
  day: PropTypes.string.isRequired,
  percent: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
PaymentListItem.defaultProps = {};
