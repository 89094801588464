import './styles/index.scss';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { StoreProvider } from 'easy-peasy';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { STRIPE_PK } from './environment';
import reportWebVitals from './reportWebVitals';
import store from './store';

const stripePromise = loadStripe(STRIPE_PK);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Elements
        cssSrc="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
        stripe={stripePromise}
      >
        <StoreProvider store={store}>
          <App />
        </StoreProvider>
      </Elements>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
