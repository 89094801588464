import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

import {
  AMOUNT_DATA, PURPOSE_DATA, RESERVE_DATA, TERM_DATA,
} from '../../../data';
import { CheckBox, RadioInput } from '../../inputs';
import { ControlButton } from '../ControlButton';

export const LoanRequest = ({
  active, next, updateState, data,
}) => {
  const updateFormState = (payload) => updateState('loanRequest', payload);

  const {
    purpose, meritAmount, meritTerm, meritReserver,
  } = data;
  return (
    <form
      method="post"
      onSubmit={(e) => { e.preventDefault(); next(); }}
      className={`form-content loan-request-container ${active ? 'active' : ''}`}
    >
      <div className="form-title">{ i18next.t('LOAN_REQUEST.TITLE') }</div>
      <div className="form-section">
        <div className="section-title">{ i18next.t('LOAN_REQUEST.PURPOSE.TITLE') }</div>
        <div className="section-content">
          {PURPOSE_DATA().map(({ title, value }) => (
            <CheckBox
              key={title}
              title={title}
              value={value}
              selected={purpose}
              onChange={(value) => updateFormState({ purpose: value })}
              required
            />
          ))}
        </div>
      </div>
      <div className="form-section">
        <div className="section-title">{ i18next.t('LOAN_REQUEST.AMOUNT.TITLE') }</div>
        <div className="section-content">
          {AMOUNT_DATA().map(({ title, value }) => (
            <RadioInput
              key={title}
              name="merit-amount"
              title={title}
              value={value}
              selected={meritAmount}
              onChange={(value) => updateFormState({ meritAmount: value })}
              required
            />
          ))}
        </div>
      </div>
      <div className="form-section">
        <div className="section-title">{ i18next.t('LOAN_REQUEST.TERM.TITLE') }</div>
        <div className="section-content">
          {TERM_DATA().map(({ title, value }) => (
            <RadioInput
              key={title}
              name="merit-term"
              title={title}
              value={value}
              selected={meritTerm}
              onChange={(value) => updateFormState({ meritTerm: value })}
              required
            />
          ))}
        </div>
      </div>
      <div className="form-section">
        <div className="section-title">{i18next.t('LOAN_REQUEST.RESERVE.TITLE')}</div>
        <div className="section-content merit-reserve">
          <div className="internal-section">
            {RESERVE_DATA(5).map(({ title, value }) => (
              <RadioInput
                key={title}
                name="merit-reserve"
                title={title}
                value={value}
                selected={meritReserver}
                onChange={(value) => updateFormState({ meritReserver: value })}
                required
              />
            ))}
          </div>
          <div className="internal-content">
            {RESERVE_DATA(0, 5).map(({ title, value }) => (
              <RadioInput
                key={title}
                name="merit-reserve"
                title={title}
                value={value}
                selected={meritReserver}
                onChange={(value) => updateFormState({ meritReserver: value })}
                required
              />
            ))}
          </div>
        </div>
      </div>
      <ControlButton
        nextTitle={i18next.t('BUTTONS.NEXT')}
        buttonPropsNext={{ type: 'submit' }}
      />
    </form>
  );
};

LoanRequest.propTypes = {
  next: PropTypes.func.isRequired,
  updateState: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Object).isRequired,
  active: PropTypes.bool.isRequired,
};
LoanRequest.defaultProps = {};
