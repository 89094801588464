/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { action, createStore, persist } from 'easy-peasy';
import i18next from 'i18next';
import { original } from 'immer';
import { extend } from 'lodash';

const store = createStore(
  persist({
    // Initial State
    auth: {
      user: { isVerified: false },
      token: '',
    },
    applications: [],
    userApplication: {},
    toasts: [],
    notifications: [],
    payments: [],
    creditCardList: [],
    selectedCreditCard: '',

    // Auth actions
    storeUserData: action((state, payload) => {
      state.auth = { ...state.auth, ...payload };
    }),
    logOut: action((state) => {
      state.auth = {};
      state.applications = [];
      state.userApplication = {};
      state.toasts = [];
      state.notifications = [];
      state.payments = [];
      state.creditCardList = [];
      state.selectedCreditCard = '';
    }),

    // Application list for admin
    // updates single application
    updateApplication: action((state, payload) => {
      const applicationsList = original(state.applications);
      const appIndex = applicationsList.findIndex(
        (application) => application._id === payload.data._id,
      );
      applicationsList[appIndex] = payload.data;
      state.applications = applicationsList;
    }),
    // updates all applications
    updateApplications: action((state, payload) => {
      state.applications = payload;
    }),

    // Application for user
    updateUserApplication: action((state, payload) => {
      if (!payload) {
        state.userApplication = {};
      } else {
        state.userApplication = payload;
      }
    }),

    // Toast API related error actions
    pushToast: action((state, payload) => {
      const id = Date.now();
      const { data } = payload;
      if (data) {
        extend(payload, { title: i18next.t(`ERROR_TITLE.${data}`), message: i18next.t(`ERRORS.${data}`) });
      }
      state.toasts = [...state.toasts, { ...payload, id }];
      setTimeout(() => store.getActions().popToast(id), 5000);
    }),
    popToast: action((state, crossId) => {
      state.toasts = state.toasts.filter(({ id }) => id !== crossId);
    }),

    // Notification actions
    addNotifications: action((state, payload) => {
      state.notifications = payload;
    }),

    // Payment actions common for both user and admin
    addPayments: action((state, payload) => {
      state.payments = payload;
    }),

    // Credit Card actions
    addCards: action((state, payload) => {
      state.creditCardList = payload;
    }),

    // Default Credit Card actions
    setSelectedCreditCard: action((state, payload) => {
      state.selectedCreditCard = payload;
    }),
  },
  {
    deny: ['toasts'], // Use this to prevent persist for listed varibales
    storage: localStorage,
  }),
);

export default store;
