import axios from 'axios';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useEffect } from 'react';

import { API_ROUTE } from '../apiRoutes';
import { AdminHomePage, AdminPaymentPage, ApplicationPage } from '../components/adminDashboard';

export const AdminDashboardPage = ({
  history: { push },
  match: { params: { page, loanId } },
}) => {
  const applications = useStoreState(({ applications }) => applications);
  const payments = useStoreState(({ payments }) => payments);
  const updateApplications = useStoreActions((actions) => actions.updateApplications);
  const pushToast = useStoreActions((actions) => actions.pushToast);
  const addPayments = useStoreActions((actions) => actions.addPayments);

  useEffect(() => {
    axios.get(API_ROUTE.FORM_FETCH_ALL)
      .then(({ data }) => {
        updateApplications(data.allApplications);
      })
      .catch((error) => {
        const { response, message } = error;
        if (response) {
          pushToast({ data: response.data.message, type: 'error' });
        } else {
          pushToast({ data: message, type: 'error' });
        }
      });
    axios.get(API_ROUTE.PAYMENT_LIST_ALL)
      .then(({ data }) => {
        addPayments(data.allPayments);
      })
      .catch((error) => {
        const { response, message } = error;
        if (response) {
          pushToast({ data: response.data.message, type: 'error' });
        } else {
          pushToast({ data: message, type: 'error' });
        }
      });
  }, []);
  return (
    <>
      {(page === 'home' && (<AdminHomePage push={push} applications={applications} />))}
      {(page === 'applications' && (<ApplicationPage push={push} loanId={loanId} applications={applications} />))}
      {(page === 'payments' && (<AdminPaymentPage push={push} payments={payments} applications={applications} />))}
    </>
  );
};

AdminDashboardPage.propTypes = {};
AdminDashboardPage.defaultProps = {};
