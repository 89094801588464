import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <path opacity={0.01} stroke="#979797" d="M.5.5h31v31H.5z" />
      <path
        d="M13 16l3 3L26 9"
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25 17v7a2 2 0 01-2 2H9a2 2 0 01-2-2V10a2 2 0 012-2h11"
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
