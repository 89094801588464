import PropTypes from 'prop-types';
import React from 'react';

import { AdminLayout } from '../AdminLayout';
import { ApplicationAnalysis } from './ApplicationAnalysis';
import { ApprovedTable } from './ApprovedTable';

const homeData = (applications = []) => {
  const approvedApplications = [];
  const declinedApplications = [];
  const waitingApplications = [];
  applications.forEach((application) => {
    const { status } = application;
    if (status === 'approved') approvedApplications.push(application);
    if (status === 'declined') declinedApplications.push(application);
    if (status === 'waiting') waitingApplications.push(application);
  });
  const approvedAmount = approvedApplications
    .map(({ loanRequest: { meritAmount } }) => meritAmount.replace(/,/g, '').replace('$', ''))
    .reduce((a, b) => Number(a) + Number(b), 0);
  const approvedSortedApplications = approvedApplications
    .sort((a, b) => Number(b.updatedAt) - Number(a.updatedAt));
  return {
    approvedAmount, approvedSortedApplications, waitingApplications, declinedApplications,
  };
};

export const AdminHomePage = ({ applications }) => {
  const {
    approvedAmount,
    approvedSortedApplications,
    waitingApplications,
    declinedApplications,
  } = homeData(applications);
  return (
    <AdminLayout title="Dashboard" subNavData={[]}>
      <div className="admin-home-dashboard">
        <ApplicationAnalysis
          approvedAmount={approvedAmount}
          approvedApplications={approvedSortedApplications}
          waitingApplications={waitingApplications}
          declinedApplications={declinedApplications}
        />
        <ApprovedTable applications={approvedSortedApplications} />
      </div>
    </AdminLayout>
  );
};
AdminHomePage.propTypes = {
  applications: PropTypes.instanceOf(Array).isRequired,
};
AdminHomePage.defaultProps = {};
