import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={48} height={48} viewBox="0 0 48 48" fill="none" {...props}>
      <path opacity={0.01} stroke="#979797" d="M.5.5h47v47H.5z" />
      <path
        d="M31.5 30l-6-6-6 6M25.5 24v13.5"
        stroke="#000432"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.085 33.585A7.5 7.5 0 0034.5 19.5h-1.89A12 12 0 1012 30.45"
        stroke="#000432"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.5 30l-6-6-6 6"
        stroke="#000432"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
