import PropTypes from 'prop-types';
import React from 'react';

export const CompanyLogo = ({ title }) => (
  <div className="company-logo-container">
    <div className="company-logo">
      {title.slice(0, 1)}
    </div>
    <div className="company-text" title={title}>{title}</div>
  </div>
);

CompanyLogo.propTypes = {
  title: PropTypes.string.isRequired,
};
CompanyLogo.defaultProps = {};
