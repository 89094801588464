import PropTypes from 'prop-types';
import React from 'react';

import { RadioCircle, RadioTick } from '../../icons';

export const FormSidebar = ({ data, active, onChange }) => {
  const activeSlides = (index) => index < data.indexOf(active);
  return (
    <div className="form-sidebar">
      <div className="form-sidebar-sticky">
        {data.map((node, index) => (
          <div
            key={node}
            className={`sidebar-node ${node === active ? 'active' : ''}`}
            onClick={activeSlides(index) ? () => onChange(node) : () => {}}
            // onClick={activeSlides(index) ? () => onChange(node) : () => onChange(node)}
          >
            <div className="node-text">{node}</div>
            {activeSlides(index) ? <RadioTick /> : <RadioCircle />}
          </div>
        ))}
      </div>
    </div>
  );
};

FormSidebar.propTypes = {
  active: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
  onChange: PropTypes.func.isRequired,
};
FormSidebar.defaultProps = {};
