import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

import { intrestCalculator, numberToAmount } from '../../../../../utils';

export const InterestCalculate = ({
  approved, newInterest, setNewInterest, currentApplication, newMeritAmount,
}) => {
  const { interest, loanRequest: { meritTerm } } = currentApplication;
  const interestAmount = intrestCalculator(
    newMeritAmount, approved ? interest : newInterest, meritTerm,
  ) / Number(meritTerm);
  return (
    <div className="month-rate">
      <div className={`rate-card ${!approved ? 'approved' : ''}`}>
        <div className="detail-desc rate-card-title">
          {numberToAmount({ number: interestAmount, limit: true })}
        </div>
        <div className="detail-title rate-card-desc">{i18next.t('APPLICATION_DETAIL_PAGE.MONTH')}</div>
      </div>
      <div className={`rate-card ${!approved ? 'approved' : ''}`}>
        <div className="detail-desc rate-card-title">
          {approved ? interest : (
            <input
              type="text"
              value={newInterest}
              onChange={({ target: { value } }) => setNewInterest(value)}
              required
            />
          )}
          %
        </div>
        <div className="detail-title rate-card-desc">{i18next.t('APPLICATION_DETAIL_PAGE.RATE')}</div>
      </div>
    </div>
  );
};
InterestCalculate.propTypes = {
  approved: PropTypes.bool.isRequired,
  currentApplication: PropTypes.instanceOf(Object).isRequired,
  newInterest: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setNewInterest: PropTypes.func.isRequired,
  newMeritAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
InterestCalculate.defaultProps = {};
