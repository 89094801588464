import i18next from 'i18next';

export const OWNERS_DATA = () => ([
  {
    title: i18next.t('OWNERSHIP.BLOCK1.OPTION1'),
    value: 'Yes',
  },
  {
    title: i18next.t('OWNERSHIP.BLOCK1.OPTION2'),
    value: 'No',
  },
]);

export const OWNER_TYPE_DATA = () => ([
  {
    title: i18next.t('OWNERSHIP.BLOCK2.OPTION1'),
    value: 'natural_person',
  },
  {
    title: i18next.t('OWNERSHIP.BLOCK2.OPTION2'),
    value: 'legal_entity',
  },
]);

export const ID_TYPE_DATA = () => ([
  {
    title: i18next.t('ID_DOC_BLOCK.OPTION1'),
    value: 'ustin',
  },
  {
    title: i18next.t('ID_DOC_BLOCK.OPTION2'),
    value: 'passport',
  },
  {
    title: i18next.t('ID_DOC_BLOCK.OPTION3'),
    value: 'alienid',
  },
  {
    title: i18next.t('ID_DOC_BLOCK.OPTION4'),
    value: 'govtid',
  },
]);
