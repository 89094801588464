import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#000432"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g strokeWidth={1.5}>
          <path d="M15 9l-6 6M9 9l6 6" />
        </g>
        <circle strokeWidth={1.4} cx={12} cy={12} r={7} />
      </g>
    </svg>
  );
}

export default SvgComponent;
