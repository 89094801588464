import PropTypes from 'prop-types';
import React from 'react';

import { Login } from '../components';

export const LoginPage = ({ history: { push }, isLogin }) => (
  <Login push={push} isLogin={isLogin} />
);

LoginPage.propTypes = {
  isLogin: PropTypes.bool,
};
LoginPage.defaultProps = {
  isLogin: false,
};
