import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

const TEXT_MAP = () => ({
  approved: i18next.t('STATUS_NODE.APPROVED'),
  waiting: i18next.t('STATUS_NODE.WAITING'),
  declined: i18next.t('STATUS_NODE.DECLINED'),
  upcoming: i18next.t('STATUS_NODE.UPCOMING'),
  overdue: i18next.t('STATUS_NODE.OVERDUE'),
});

export const StatusNode = ({ mode }) => (
  <div className={`status-node ${mode}`}>
    {TEXT_MAP()[mode]}
  </div>
);

StatusNode.propTypes = {
  mode: PropTypes.string,
};
StatusNode.defaultProps = {
  mode: '',
};
