import PropTypes from 'prop-types';
import React from 'react';

import { UploadInput } from '../../inputs';

export const DocumentUpload = ({
  className, title, subTitle, description, onUpload, required,
}) => (
  <div className={`document-upload-content-container ${className}`}>
    <div className="form-text-title document-upload-head">{title}</div>
    <div className="document-upload-content">
      <div className="document-upload-upload">
        <a
          href="https://www.google.com"
          target="_blank"
          rel="noreferrer"
          className="form-description-text nda-link"
        >
          {subTitle}
        </a>
        <UploadInput onUpload={onUpload} required={required} />
      </div>
      <div className="form-light-description upload-description">{description}</div>
    </div>
  </div>
);

DocumentUpload.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string.isRequired,
  onUpload: PropTypes.func.isRequired,
  subTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  required: PropTypes.bool,
};
DocumentUpload.defaultProps = {
  className: '',
  required: false,
};
