import i18next from 'i18next';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { PURPOSE_DATA, RESERVE_DATA } from '../../../data/loanRequestData';
import { numberToAmount, numberToMonth, valueToTitle } from '../../../utils';

const YOUR_PLAN_DATA = ({
  meritAmount, interest, meritTerm, meritReserver, purpose,
}) => [
  {
    title: i18next.t('YOUR_PLAN.SUB_TITLE1'),
    value: numberToAmount({ number: meritAmount }),
  },
  {
    title: i18next.t('YOUR_PLAN.SUB_TITLE2'),
    value: `${interest}%`,
  },
  {
    title: i18next.t('YOUR_PLAN.SUB_TITLE3'),
    value: numberToMonth(meritTerm),
  },
  {
    title: i18next.t('YOUR_PLAN.SUB_TITLE4'),
    value: valueToTitle([meritReserver], RESERVE_DATA()),
    className: 'white-text',
  },
  {
    title: i18next.t('YOUR_PLAN.SUB_TITLE5'),
    value: valueToTitle(purpose, PURPOSE_DATA()).join(', '),
    className: 'extended',
  },
];

export const YourPlan = ({ application }) => {
  const {
    loanRequest: {
      meritAmount, meritTerm, meritReserver, purpose,
    }, interest,
  } = !isEmpty(application) ? application : { loanRequest: {} };
  return (
    <div className="your-plan">
      <div className="your-plan-title block-title">{i18next.t('YOUR_PLAN.TITLE')}</div>
      <div className="your-plan-content">
        {YOUR_PLAN_DATA({
          meritAmount, interest, meritTerm, meritReserver, purpose,
        }).map(({ title, value, className }) => (
          <div key={title} className={`plan-block ${className || ''}`}>
            <div className="plan-title">{title}</div>
            <div className="plan-description">{value}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

YourPlan.propTypes = {
  application: PropTypes.instanceOf(Object).isRequired,
};
YourPlan.defaultProps = {};
