import i18next from 'i18next';
import React, { useState } from 'react';

import { BoosterCard } from './BoosterCard';
import { BoosterUpload } from './BoosterUpload';

const boosterCardData = () => [
  {
    title: i18next.t('BOOSTER.COMMERCIAL_LEASE'),
    description: i18next.t('BOOSTER.COMMERCIAL_LEASE_DESCRIPTION'),
  },
  {
    title: i18next.t('BOOSTER.COMMERCIAL_UTILITIES'),
    description: i18next.t('BOOSTER.COMMERCIAL_UTILITIES_DESCRIPTION'),
  },
  {
    title: i18next.t('BOOSTER.SUPPLIERS_VENDORS'),
    description: i18next.t('BOOSTER.SUPPLIERS_VENDORS_DESCRIPTION'),
  },
];

export const Booster = () => {
  const [showInput, setShowInput] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const onUpload = () => {
    setShowInput(false);
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 6000);
  };

  return (
    <div className="booster-container">
      {!showInput ? (
        <>
          {showSuccess && <div className="upload-success">{i18next.t('BOOSTER.UPLOAD_SUCCESS')}</div>}
          <div className="booster-list-card">
            {boosterCardData().map(({ title, description }) => (
              <BoosterCard
                key={title}
                title={title}
                description={description}
                onClick={() => { setShowInput(true); }}
              />
            ))}
          </div>
        </>
      ) : (
        <BoosterUpload
          onUpload={onUpload}
          onCancle={() => { setShowInput(false); setShowSuccess(false); }}
        />
      )}
    </div>
  );
};

Booster.propTypes = {};
Booster.defaultProps = {};
