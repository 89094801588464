import { isEmpty } from 'lodash';
import React from 'react';
import { Redirect } from 'react-router-dom';

import {
  AdminDashboardPage, DashboardPage, FormPage, LoanApprovedPage,
  LoanRejectedPage, LoginPage, SettingPage, UnderReviewPage,
} from './pages';

export const ROUTES = (userData) => {
  if (!isEmpty(userData)) {
    const { isAdmin } = userData;
    if (isAdmin) {
      return [
        {
          path: '/admin-dashboard/:page/:loanId',
          render: (props) => <AdminDashboardPage {...props} />,
        },
        {
          path: '/admin-dashboard/:page',
          render: (props) => <AdminDashboardPage {...props} />,
        },
        {
          path: '/admin-dashboard',
          render: () => <Redirect to="/admin-dashboard/home" />,
        },
        {
          path: '/',
          render: () => <Redirect to="/admin-dashboard" />,
        },
      ];
    }
    return [
      {
        path: '/dashboard/:page',
        render: (props) => <DashboardPage {...props} />,
      },
      {
        path: '/dashboard',
        render: () => <Redirect to="/dashboard/home" />,
      },
      {
        path: '/loan-form',
        render: (props) => <FormPage {...props} />,
      },
      {
        path: '/review',
        render: (props) => <UnderReviewPage {...props} />,
      },
      {
        path: '/approved',
        render: (props) => <LoanApprovedPage {...props} />,
      },
      {
        path: '/rejected',
        render: (props) => <LoanRejectedPage {...props} />,
      },
      {
        path: '/setting',
        render: (props) => <SettingPage {...props} />,
      },
      {
        path: '/',
        render: () => <Redirect to="/loan-form" />,
      },
    ];
  }
  return [{
    path: '/login',
    render: (props) => <LoginPage isLogin {...props} />,
  },
  {
    path: '/signup',
    render: (props) => <LoginPage {...props} />,
  },
  {
    path: '/',
    render: () => <Redirect to="/login" />,
  }];
};
