import PropTypes from 'prop-types';
import React from 'react';

import { AdminSidebar } from './AdminSidebar';
import { SubNavbar } from './SubNavbar';
import { SuperNavbar } from './SuperNavbar';

export const AdminLayout = ({
  children, subNavData, title, subNavOnClick, subNavActive,
}) => (
  <div className="admin-dashboard-screen-container">
    <AdminSidebar />
    <div className="admin-dashboard-content">
      <SuperNavbar title={title} />
      <SubNavbar active={subNavActive} subNavData={subNavData} onClick={subNavOnClick} />
      {children}
    </div>
  </div>
);

AdminLayout.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  subNavData: PropTypes.instanceOf(Array),
  subNavOnClick: PropTypes.func,
  subNavActive: PropTypes.string,
};
AdminLayout.defaultProps = {
  subNavData: [],
  subNavOnClick: () => {},
  subNavActive: '',
};
