import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <path opacity={0.01} stroke="#979797" d="M31.5.5v31H.5V.5z" />
      <path
        d="M19 10l-6 6 6 6"
        stroke="#000432"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
