import axios from 'axios';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import { API_ROUTE } from '../../apiRoutes';
import { extensionMap } from '../../constants';
import { HelpIcon, UploadClear } from '../../icons';

const ERROR_DATA = (formats) => [i18next.t('UPLOAD_INPUT.WRONG_FILE_FORMAT', { formats }), i18next.t('UPLOAD_INPUT.FILE_UPLOAD_ERROR')];

export const UploadInput = ({
  onUpload, fileTypes, value, className, title, hideFileTitle, ...props
}) => {
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const fileInput = useRef();
  const errorRef = useRef();
  const ALL_ERROR_MESSAGES = ERROR_DATA(fileTypes.map((node) => `.${extensionMap[node]}`).join(', '));

  const showError = (error) => {
    setError(error);
    errorRef.current.click();
    errorRef.current.focus();
  };

  const uploadFile = (files) => {
    setName('');
    const formData = new FormData();
    const file = files[0];
    formData.append('file', file);
    if (!fileTypes.includes(file?.type)) {
      showError(ALL_ERROR_MESSAGES[0]);
      fileInput.current.value = '';
    } else {
      setName(file.name);
      axios.post(API_ROUTE.FORM_UPLOAD, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then((res) => {
          onUpload(res.data.url);
        })
        .catch(() => {
          showError(ALL_ERROR_MESSAGES[1]);
        });
    }
  };

  const clearInput = (e) => {
    e.stopPropagation();
    fileInput.current.value = '';
    onUpload('');
    setName('');
  };
  return (
    <div className={`upload-input-container ${className}`}>
      <div className="upload-input">
        <input
          ref={fileInput}
          type="file"
          onChange={(e) => uploadFile(e.target.files)}
          onClick={(e) => e.stopPropagation()}
          {...props}
          {...(hideFileTitle ? { title: '' } : {})}
        />
        {title || (name || i18next.t('UPLOAD_INPUT.UPLOAD_DOCUMENT'))}
        {name && <UploadClear className="clear-icon" onClick={clearInput} />}
      </div>
      <div
        className="hidden-tooltip-container"
        ref={errorRef}
        tabIndex="-1"
        data-for="upload-tooltip"
        data-tip={error}
        data-event="click focus"
        data-event-off="blur"
        onClick={(e) => e.stopPropagation()}
      />
      <HelpIcon
        className="help-icon"
        data-for="help-tooltip"
        data-tip={i18next.t('UPLOAD_INPUT.UPLOAD_TOOLTIP')}
        data-iscapture="true"
        data-event="click focus"
        data-event-off="blur"
      />
    </div>
  );
};

UploadInput.propTypes = {
  onUpload: PropTypes.func,
  fileTypes: PropTypes.instanceOf(Array),
  value: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  hideFileTitle: PropTypes.bool,
};
UploadInput.defaultProps = {
  onUpload: () => { },
  fileTypes: ['image/png', 'application/pdf', 'image/jpeg'],
  value: '',
  className: '',
  title: '',
  hideFileTitle: false,
};
