import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import {
  ApplicationIcon, CardIcon, HomeIcon, Logo,
} from '../../icons';

const ADMIN_SIDEBAR = [
  { title: 'Dashboard', Icon: HomeIcon, link: 'home' },
  { title: 'Applications', Icon: ApplicationIcon, link: 'applications' },
  { title: 'Payments', Icon: CardIcon, link: 'payments' },
];

const AdminSidebarComponent = () => (
  <div className="admin-sidebar-component">
    <Logo className="sidebar-logo" />
    <div className="sidebar-nav-container">
      {ADMIN_SIDEBAR.map(({ title, link }) => (
        <NavLink
          key={title}
          to={`/admin-dashboard/${link}`}
          activeClassName="active"
          className="sidebar-link"
        >
          <div className="link-nav">{title}</div>
        </NavLink>
      ))}
    </div>
  </div>
);

AdminSidebarComponent.propTypes = {};
AdminSidebarComponent.defaultProps = {};

export const AdminSidebar = withRouter(AdminSidebarComponent);
