import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { numberToAmount, numberToMonth } from '../../../utils';

export const ApprovedListItem = ({
  _id, legalName, meritAmount, interest, meritTerm,
}) => (
  <Link to={`/admin-dashboard/applications/${_id}`} className="approved-list-item">
    <div className="approved-list-item-text overflow" title={legalName}>{legalName}</div>
    <div className="approved-list-item-text">{numberToAmount({ number: meritAmount })}</div>
    <div className="approved-list-item-text lite">{i18next.t('APPROVED_LIST_ITEM.INTEREST', { interest })}</div>
    <div className="approved-list-item-text lite">{numberToMonth(meritTerm)}</div>
  </Link>
);

ApprovedListItem.propTypes = {
  _id: PropTypes.string.isRequired,
  interest: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  legalName: PropTypes.string.isRequired,
  meritAmount: PropTypes.string.isRequired,
  meritTerm: PropTypes.string.isRequired,
};
ApprovedListItem.defaultProps = {};
