import PropTypes from 'prop-types';
import React from 'react';

export const SubNavbar = ({ subNavData, onClick, active }) => (
  <div className="admin-sub-navbar">
    {subNavData.map(({ title, value }) => (
      <div
        key={value}
        className={`sub-navbar-link ${value === active ? 'active' : ''}`}
        onClick={() => onClick(value)}
      >
        {title}
      </div>
    ))}
  </div>
);

SubNavbar.propTypes = {
  subNavData: PropTypes.instanceOf(Array),
  onClick: PropTypes.func,
  active: PropTypes.string,
};
SubNavbar.defaultProps = {
  subNavData: [],
  onClick: () => {},
  active: '',
};
