import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

import { numberToAmount } from '../../../../../utils';
import { RequestedAmountInput } from '../../../../common';
import { DATA_CARD_MAP, DATA_MAP } from '../../data';
import { BusinessShort } from './BusinessShort';
import { BusinessSummary } from './BusinessSummary';
import { InstallmentDeduction } from './InstallmentDeduction';
import { InterestCalculate } from './InterestCalculate';
import { StatusButton } from './StatusButton';

export const ApplicationOverview = ({
  isForm, approveApplication, newInterest,
  setNewInterest, mid, setMid, currentApplication,
  newMeritAmount, setNewMeritAmount,
}) => {
  const {
    businessProfile, loanRequest, status, monthlyInstallmentDate,
  } = currentApplication;
  const { meritAmount } = loanRequest;
  const approved = status === 'approved';
  const declined = status === 'declined';
  const Form = isForm ? 'div' : 'form';
  return (
    <div className="application-details-container">
      <BusinessSummary data={DATA_MAP(businessProfile)} />
      <Form onSubmit={approveApplication} className="application-bussiness-request">
        <div className="detail-block-dark">
          <div className="detail-title">{i18next.t('APPLICATION_DETAIL_PAGE.KEYS.TITLE18')}</div>
          <div className="detail-desc">
            {approved || declined
              ? numberToAmount({ number: meritAmount })
              : (
                <RequestedAmountInput
                  value={newMeritAmount}
                  onChange={setNewMeritAmount}
                  required
                />
              )}
          </div>
        </div>
        <BusinessShort data={DATA_CARD_MAP(loanRequest)} />
        <div className="application-divider" />
        <InterestCalculate
          approved={approved || declined}
          newInterest={newInterest}
          setNewInterest={setNewInterest}
          currentApplication={currentApplication}
          newMeritAmount={newMeritAmount}
        />
        <InstallmentDeduction
          approved={approved || declined}
          monthlyInstallmentDate={monthlyInstallmentDate}
          mid={mid}
          setMid={setMid}
        />
        <StatusButton status={status} />
      </Form>
    </div>
  );
};

ApplicationOverview.propTypes = {
  isForm: PropTypes.bool.isRequired,
  currentApplication: PropTypes.instanceOf(Object).isRequired,
  approveApplication: PropTypes.func.isRequired,
  mid: PropTypes.number.isRequired,
  newInterest: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setMid: PropTypes.func.isRequired,
  setNewInterest: PropTypes.func.isRequired,
  newMeritAmount: PropTypes.string.isRequired,
  setNewMeritAmount: PropTypes.func.isRequired,
};
ApplicationOverview.defaultProps = {};
