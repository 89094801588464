import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <path opacity={0.01} stroke="#979797" d="M.5.5h31v31H.5z" />
      <path
        d="M24 25v-2a4 4 0 00-4-4h-8a4 4 0 00-4 4v2"
        stroke="#000432"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx={16}
        cy={11}
        r={4}
        stroke="#000432"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;
