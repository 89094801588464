import PropTypes from 'prop-types';
import * as React from 'react';

function SvgComponent({ dark, ...props }) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path opacity={0.01} stroke="#979797" d="M.5.5h23v23H.5z" />
      <path
        d="M22 12.08V13a10 10 0 11-5.93-9.14"
        stroke={dark ? '#323232' : '#4C5AFF'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 5L12 15.01l-3-3"
        stroke={dark ? '#323232' : '#4C5AFF'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

SvgComponent.propTypes = {
  dark: PropTypes.bool,
};

SvgComponent.defaultProps = {
  dark: false,
};

export default SvgComponent;
