import PropTypes from 'prop-types';
import React from 'react';

export const ControlButton = ({
  onNext, onPrev, prevTitle, nextTitle, buttonPropsPrev, buttonPropsNext,
}) => (
  <div className="button-section">
    {prevTitle && (
      <button
        type="button"
        onClick={onPrev}
        className="primary-button prev-button"
        {...buttonPropsPrev}
      >
        {prevTitle}
      </button>
    )}
    {nextTitle && (
      <button
        type="button"
        onClick={onNext}
        className="primary-button next-button"
        {...buttonPropsNext}
      >
        {nextTitle}
      </button>
    )}
  </div>
);

ControlButton.propTypes = {
  nextTitle: PropTypes.string,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  prevTitle: PropTypes.string,
  buttonPropsPrev: PropTypes.instanceOf(Object),
  buttonPropsNext: PropTypes.instanceOf(Object),
};
ControlButton.defaultProps = {
  onPrev: () => {},
  onNext: () => {},
  nextTitle: '',
  prevTitle: '',
  buttonPropsPrev: {},
  buttonPropsNext: {},
};
