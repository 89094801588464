import PropTypes from 'prop-types';
import * as React from 'react';

function SvgComponent({ dark, ...props }) {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path opacity={0.01} stroke="#979797" d="M.5.5h23v23H.5z" />
      <circle
        cx={12}
        cy={12}
        r={8}
        stroke="#000"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        opacity={dark ? 1 : 0.498}
      />
    </svg>
  );
}

SvgComponent.propTypes = {
  dark: PropTypes.bool,
};

SvgComponent.defaultProps = {
  dark: false,
};

export default SvgComponent;
