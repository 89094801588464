import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg width={55} height={53} viewBox="0 0 55 53" fill="none" {...props}>
      <circle cx={30.5} cy={48.5} r={30.5} fill="#4C5AFF" />
      <circle cx={55.5} cy={21.5} r={21.5} fill="#00A688" />
    </svg>
  );
}

export default SvgComponent;
