import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

import { numberToAmount } from '../../../utils';

export const ApplicationAnalysis = ({
  approvedAmount, approvedApplications, waitingApplications, declinedApplications,
}) => {
  const DATA = [
    { title: i18next.t('ADMIN.DASHBOARD.TOTAL_FUNDED'), value: numberToAmount({ number: approvedAmount }), main: true },
    { title: i18next.t('ADMIN.DASHBOARD.FUNDS_APPROVED'), value: approvedApplications.length },
    { title: i18next.t('ADMIN.DASHBOARD.WAITING_APPS'), value: waitingApplications.length },
    { title: i18next.t('ADMIN.DASHBOARD.DECLINED_APPS'), value: declinedApplications.length },
  ];
  return (
    <div className="fund-analysis">
      {DATA.map(({ title, value, main }) => (
        <div key={title} className={`fund-data-card ${main ? 'green' : ''}`}>
          <div className="fund-data-head">{title}</div>
          <div className="fund-data-content">{value}</div>
        </div>
      ))}
    </div>
  );
};

ApplicationAnalysis.propTypes = {
  approvedAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  approvedApplications: PropTypes.instanceOf(Array).isRequired,
  declinedApplications: PropTypes.instanceOf(Array).isRequired,
  waitingApplications: PropTypes.instanceOf(Array).isRequired,
};
ApplicationAnalysis.defaultProps = {};
