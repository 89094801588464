import i18next from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';

import { ApprovedListItem } from './ApprovedListItem';

export const ApprovedTable = ({ applications }) => (
  <div className="approved-history-section">
    <div className="approved-history-title">
      {i18next.t('ADMIN.DASHBOARD.HISTORY')}
    </div>
    <div className="approved-history-list-container">
      {applications.map(({
        _id,
        loanRequest: { meritAmount, meritTerm },
        businessProfile: { legalName },
        interest = '',
      }) => (
        <ApprovedListItem
          key={_id}
          _id={_id}
          legalName={legalName}
          meritAmount={meritAmount}
          interest={interest}
          meritTerm={meritTerm}
        />
      ))}
    </div>
  </div>
);

ApprovedTable.propTypes = {
  applications: PropTypes.instanceOf(Array).isRequired,
};
ApprovedTable.defaultProps = {};
